import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import backendUrl from "../../../../globalVars";
import {Button, DialogContentText} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import JsonForm from "../../../../FormCreator/JsonForm";
import addSetSchema from "../../../../json_shema/addSetShema/addSetSchema.json";
import DialogActions from "@material-ui/core/DialogActions";
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import runSetSchema from "../../../../json_shema/runSetSchema/runSetSchema.json";
import runSetUiSchema from "../../../../json_shema/runSetSchema/runSetUiSchema.json";
import Typography from '@material-ui/core/Typography';
import {GridColDef, DataGrid, GridRowParams, GridSortModel, ValueFormatterParams} from "@material-ui/data-grid";
import Pagination from "@material-ui/lab/Pagination";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import editSetSchema from "../../../../json_shema/existingSetSchema/existingSetSchema.json";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    searchFieldStyle: {
      margin: theme.spacing(2),
    },
    accordionStyle: {
      margin: theme.spacing(2),
    },
    setsTableStyle: {
      overflow: 'auto',
      overflowX: 'hidden',
      height: 'calc(100vh - 112px)',
      borderRadius: 0,
    },
    import: {
      display: 'none',
    },
    clickableTitle: {
      cursor: 'pointer',
    },
    paginationStyle: {
      paddingRight: 10
    }
  }),
);

interface Set {
  id: string,
  setId: string
  name: string,
  description: string
  caseCount: number
  stepCount: number
  createdAt: string
  updatedAt: string
};

interface UpdateSet {
  id: string,
  name: string,
  description: string
};

const getSets = async (basicAuthToken: string, currentProjectId: string, setSets: Function) => {
  const url = backendUrl + "/sets?projectId=" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const sets = await response.json();

    setSets(
      sets.map((item: Set) => {
        return {
          name: item.name,
          id: item.setId,
          description: item.description,
          caseCount: item.caseCount,
          stepCount: item.stepCount,
          created: item.createdAt,
          updated: item.updatedAt,
        }
      })
    )
  }
};

const addSet = async (formSubmitContext: { basicAuthToken: string, currentProjectId: string, setSets: Function, uploadedFile: File | null }, newSet: Set) => {
  const url = backendUrl + "/sets";

  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(newSet)
  });

  if (response.status === 201) {
    if (formSubmitContext.uploadedFile) {
      const receivedSet: { setId: string } = await response.json();

      await importCases(formSubmitContext.basicAuthToken, receivedSet.setId, formSubmitContext.uploadedFile);
    }

    await getSets(formSubmitContext.basicAuthToken, formSubmitContext.currentProjectId, formSubmitContext.setSets);
  }
};

const updateSet = async (formSubmitContext: { basicAuthToken: string, editingSetId: string, currentProjectId: string, setSets: Function }, updatedSet: UpdateSet) => {
  const url = backendUrl + "/sets/" + formSubmitContext.editingSetId;
  const { name, description } = updatedSet;

  const response = await fetch(url, {
    method: "PUT",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify({ name, description })
  });

  if (response.ok) {
    getSets(formSubmitContext.basicAuthToken, formSubmitContext.currentProjectId, formSubmitContext.setSets)
  }
};

const deleteSet = async (basicAuthToken: string, setId: string) => {
  const url = backendUrl + "/sets";

  await fetch(url + '/' + setId, {
    method: "DELETE",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken,
      "Content-Type": "application/json"
    })
  });
};

const importCases = async (basicAuthToken: string, setId: string, file: File) => {
  const format = /xlsx$/gi.test(file.name) ? 'xlsx' : 'json';
  const url = backendUrl + "/sets/" + setId + "/actions/import-cases?format=" + format;

  const formData = new FormData();
  // @ts-ignore
  formData.append('file', file)

  await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken,
    }),
    body: formData
  });
};

interface Config {
  id: string,
  name: string
};

const getConfigs = async (basicAuthToken: string, currentProjectId: string, setConfigsList: Function) => {
  const arr: Config[] = [];

  const url = backendUrl + "/configs?projectId=" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const configsList = await response.json();

    configsList.forEach((item: { configId: string, name: string }) => {
      const obj = {
        id: item.configId,
        name: item.name
      };

      arr.push(obj)
    })
  }

  setConfigsList(arr);
};

interface runObj {
  projectId: string,
  setId: string,
  configId: string,
  name: string
};

const runSet = async (formSubmitContext: { basicAuthToken: string, setCookie: Function, cookies: {configId: string, setId: string}[] }, runObj: runObj) => {
  const url = backendUrl + "/runs";

  formSubmitContext.setCookie('lastConfigs', !formSubmitContext.cookies?.find(cookie => runObj.setId === cookie.setId)? [...formSubmitContext.cookies, {configId: runObj.configId, setId: runObj.setId}]: [...formSubmitContext.cookies.map(cookie => runObj.setId === cookie.setId? {...cookie, configId: runObj.configId}: cookie)], { path: '/' });

  await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(runObj)
  });
};

function CustomPagination(props: any) {
  const { state } = props;
  const classes = useStyles();

  const {t} = useTranslation();

  const [uploadedFile, setUploadedFile] = useState<File | null>();
  const [uploadedFileName, setUploadedFileName] = useState(t("sets.upload_text"));

  return (
    <div style={{display: "flex"}}>

      <Toolbar style={{ justifyContent: "flex-end" }}>
        <Pagination className={classes.paginationStyle}
                    style={{justifyContent: "flex-start"}}
                    color="secondary"
                    page={props.page + 1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) => props.setPage(value - 1)}
        />
        <IconButton color="inherit" edge={'start'} onClick={() => props.handleClickModal('addSet')}>
          <AddBoxIcon/>
        </IconButton>
        <Dialog open={props.openModal.addSet}
                onClose={() => props.handleClickModal('addSet')}
                aria-labelledby="form-dialog-title">
          <AppBar style={{position: 'relative'}}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => props.handleClickModal('addSet')}
                          aria-label="close">
                <CloseIcon/>
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <JsonForm schema={addSetSchema}
                      open={props.openModal.addSet}
                      formSubmitContext={{
                        "basicAuthToken": props.authKey,
                        "currentProjectId": props.currentProjectId,
                        "setSets": props.setSets,
                        "uploadedFile": uploadedFile
                      }}
                      handleClose={() => props.handleClickModal('addSet')}
                      onSubmit={addSet}
                      record={{
                        projectId: props.currentProjectId
                      }}
                      import
                      importButton={
                        <div style={{display: "flex", margin: "20px 0"}}>
                          <input name="file"
                                 id="file"
                                 type="file"
                                 className={classes.import}
                                 onChange={(e) => {
                                   // @ts-ignore
                                   setUploadedFileName(e.target.files[0].name)
                                   // @ts-ignore
                                   setUploadedFile(e.target.files[0])
                                 }}
                                 accept={'.xlsx,.json'}
                          />
                          <label htmlFor="file">
                            <Button variant="contained" color="primary" component="span">
                              {t("sets.import")}
                            </Button>
                          </label>
                          <Typography style={{padding: 5, marginLeft: 10}}>
                            {uploadedFileName}
                          </Typography>
                        </div>
                      }/>
          </DialogContent>
        </Dialog>
      </Toolbar>
    </div>
  )
};

const SetsPanel = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [sets, setSets] = useState([]);
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState({
    run: false,
    addSet: false,
    editSet: false,
    importCases: false,
    delete: false
  });
  const [currentRunSetId, setCurrentRunSetId] = useState('');
  const [currentSetName, setCurrentSetName] = useState('');
  const [configsList, setConfigsList] = useState([]);
  const [deletingSet, setDeletingSet] = useState('');
  const [editingSetId, setEditingSetId] = React.useState('none');
  const [editingSetRecord, setEditingSetRecord] = React.useState({});
  // @ts-ignore
  const [cookies, setCookie] = useCookies(['lastConfigs']);

  const handleClickModal = (modalName: string) => {
    setOpenModal(prevState => {
      // @ts-ignore
      return {...prevState, [modalName]: !openModal[modalName]}
    })
  };

  useEffect(() => {
    getSets(props.authKey, props.currentProjectId, setSets);
    // eslint-disable-next-line
  }, []);

  const onRowClicked = (params: GridRowParams) => {
    props.resetCasePage()
    props.setCurrentSetId(String(params.row.id));
    props.getBreadcrumbs({currentSet: params.row.name, currentCase: ''});
    props.setCurrentCaseId('none')
  };

  const sortModel: GridSortModel = [
    {
      field: 'created',
      sort: 'desc',
    },
  ];

  const setsColumns: GridColDef[] = [
      {
        field: 'created',
        hide: true,
        disableColumnMenu: true,
      },
      {
        field: 'name',
        headerName: t("sets.sets_table.set"),
        headerAlign: 'center',
        flex: 1,
      },
      {
        field: 'description',
        headerName: t("sets.sets_table.description"),
        headerAlign: 'center',
        flex: 1,
        renderCell: (params: ValueFormatterParams) =>
          <Tooltip title={<Typography>{params.row.description}</Typography>} enterDelay={300}>
            <Typography>{params.row.description}</Typography>
          </Tooltip>
      },
      {
        field: 'caseCount',
        headerName: t("sets.sets_table.cases"),
        headerAlign: 'center',
        disableColumnMenu: true,
        align: 'center',
        width: 75,
      },
      {
        field: 'stepCount',
        headerName: t("sets.sets_table.steps"),
        headerAlign: 'center',
        disableColumnMenu: true,
        align: 'center',
        width: 75,
      },
      {
        field: 'actions',
        headerName: ' ',
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        align: 'center',
        width: 170,
        disableClickEventBubbling: true,
        renderCell:
          (params: ValueFormatterParams) => (
            <Toolbar style={{margin: 0, padding: 0}}>
              <IconButton color="inherit" aria-label="menu" onClick={() => {

                setEditingSetId(params.row.id.toString())

                // @ts-ignore
                const obj = {...params.row};
                // @ts-ignore
                delete obj.id;
                // @ts-ignore
                delete obj.updated;
                // @ts-ignore
                delete obj.created;
                // @ts-ignore
                setEditingSetRecord(obj)

                handleClickModal('editSet');
              }}>
                <EditIcon/>
              </IconButton>
              <Dialog open={openModal.editSet}
                      onClose={() => {
                        handleClickModal('editSet')
                      }}
                      aria-labelledby="form-dialog-title"
                      maxWidth="md">
                <AppBar style={{position: 'relative'}}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => handleClickModal('editSet')}
                                aria-label="close">
                      <CloseIcon/>
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <JsonForm schema={editSetSchema}
                            record={{
                              ...editingSetRecord,
                              projectId: props.currentProjectId
                            }}
                            formSubmitContext={{
                              "basicAuthToken": props.authKey,
                              "currentProjectId": props.currentProjectId,
                              "editingSetId": editingSetId,
                              "setSets": setSets
                            }}
                            open={openModal.editSet}
                            onSubmit={updateSet}
                            handleClose={() => {
                              handleClickModal('editSet')
                            }}/>
                </DialogContent>
              </Dialog>
              <IconButton color="inherit" aria-label="menu" component={"span"} onClick={() => {
                setDeletingSet(params.row.id.toString());
                handleClickModal('delete');
              }}>
                <DeleteForeverOutlinedIcon/>
              </IconButton>
              <Dialog open={openModal.delete}
                      onClose={() => handleClickModal('delete')}
                      aria-labelledby="form-dialog-title">
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to delete selected items?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    handleClickModal('delete')
                  }}
                          color="primary">
                    Disagree
                  </Button>
                  <Button onClick={async () => {
                    await deleteSet(props.authKey, deletingSet);
                    await getSets(props.authKey, props.currentProjectId, setSets);

                    handleClickModal('delete');
                  }}
                          color="primary">
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
              <IconButton color="inherit" aria-label="menu" component={"span"} onClick={async () => {
                await getConfigs(props.authKey, props.currentProjectId, setConfigsList);

                setCurrentRunSetId(String(params.row.id));
                setCurrentSetName(params.row.name);

                handleClickModal('run')
              }}>
                <PlayCircleFilledWhiteOutlinedIcon/>
              </IconButton>
              <Dialog open={openModal.run}
                      onClose={() => handleClickModal('run')}
                      aria-labelledby="form-dialog-title"
                      fullWidth={true}
                      maxWidth='sm'>
                <AppBar style={{position: 'relative'}}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => handleClickModal('run')} aria-label="close">
                      <CloseIcon/>
                    </IconButton>
                    <Typography style={{marginLeft: '10px'}}>{`Run "${currentSetName}"?`}</Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <JsonForm schema={runSetSchema}
                            uiSchema={runSetUiSchema}
                            record={{
                              projectId: props.currentProjectId,
                              setId: currentRunSetId,
                              name: currentSetName,
                              configId: cookies.lastConfigs?.find((config: {setId: string}) => currentRunSetId === config.setId)?.configId
                            }}
                            formSubmitContext={{
                              "basicAuthToken": props.authKey,
                              "setCookie": setCookie,
                              "cookies": cookies.lastConfigs || []
                            }}
                            enumSource={{
                              configId: configsList
                            }}
                            open={openModal.run}
                            onSubmit={runSet}
                            handleClose={() => {
                              handleClickModal('run')
                            }}/>
                </DialogContent>
              </Dialog>
            </Toolbar>
          )
      }
    ]
  ;

  return (
    <>
      <DataGrid
        columns={setsColumns}
        rows={sets}
        className={classes.setsTableStyle}
        density='compact'
        pageSize={20}
        sortModel={sortModel}
        hideFooterSelectedRowCount={true}
        page={page}
        components={{
          Pagination: (gridProps) => {
            return <CustomPagination {...gridProps}
                                     page={page}
                                     setPage={setPage}
                                     openModal={openModal}
                                     handleClickModal={handleClickModal}
                                     authKey={props.authKey}
                                     setSets={setSets}
                                     currentProjectId={props.currentProjectId}
                                     setCurrentSetId={props.setCurrentSetId}/>
          }
        }}
        onRowClick={onRowClicked}
      />
    </>
  )
}

export default SetsPanel;