import React, {useState} from 'react';
import backendUrl from '../globalVars';
import {Button, Grid} from "@material-ui/core";
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © BSS '}
      {new Date().getFullYear()}
    </Typography>
  )
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    content: {
      padding: theme.spacing(10, 0, 0),
    }
  })
);

interface ValidateUserOptions {
  login: string
  password: string
}

const validateUser = async (obj: ValidateUserOptions, callback: Function, updatePath: Function, logIn: Function, openSnackbarError: Function) => {
  const basicAuthToken = btoa(obj.login + ':' + obj.password);
  let error: string|null = null;

  const response = await fetch(backendUrl + '/users/me', {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  })
  .catch(err => {
    error = err.message;
    
    return null
  });

  if (response?.status === 200 || !error) {
    const user = await response?.json();

    callback(basicAuthToken, user.userId);
    updatePath('/projects');
    logIn(true);
  } else {
    return (
      openSnackbarError(error || 'Wrong login or password!')
    )
  }
}

const AuthorizationForm = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [loginFormData, setLoginFormData] = useState({
    login: '',
    password: ''
  });

  return (
    <div className={classes.root}>

      <Grid container direction="column" alignItems="center" className={classes.content}>
        <Grid item xs={4} sm={4}>
          <Avatar className={classes.avatar}>
            <LockRoundedIcon/>
          </Avatar>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Typography component="h1"
                      variant="h5">
            {t("common.sign_in")}
          </Typography>
        </Grid>
        <Grid item xs={5} sm={5}>
          <form autoComplete={'off'}
                className={classes.form}>
            <TextField fullWidth
                       required
                       onChange={e => {
                         const obj = loginFormData;

                         obj.login = e.target.value;

                         setLoginFormData(obj)
                       }}
                       label={t("common.username")}
                       margin="normal"
                       variant="outlined"/>
            <TextField fullWidth
                       required
                       type="password"
                       onChange={e => {
                         const obj = loginFormData;

                         obj.password = e.target.value;

                         setLoginFormData(obj)
                       }}
                       label={t("common.password")}
                       margin="normal"
                       variant="outlined"/>
            <Button fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      validateUser(loginFormData, props.getAuthKey, props.updatePath, props.logIn, props.openSnackbarError)
                    }}>
              Sign in
            </Button>
          </form>
        </Grid>
        <Grid item xs={4} sm={4}>
          <Copyright/>
        </Grid>
      </Grid>
    </div>
  );
}

export default AuthorizationForm;