import React, {useState} from "react";
import Paper from '@material-ui/core/Paper';
import SetsPanel from "./SetsComponents/SetsPanel/SetsPanel";
import CaseEditor from "./SetsComponents/CasePanel/CaseEditor";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {SplitPane} from 'react-collapse-pane';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperHeight: {
      height: 'calc(100vh - 112px)',
    },
    splitPane: {
      height: 'calc(100vh - 112px) !important',
    }
  })
);

const SetsSection = (props: any) => {
  const classes = useStyles();

  const [currentSetId, setCurrentSetId] = useState('none');
  const [currentCaseId, setCurrentCaseId] = useState('none');
  const [casePage, setCasePage] = useState(0);

  const handlePage = (page: number) => {
    setCasePage(page)
  };

  const resizerOptions = {
    css: {
      width: '5px',
      background: 'rgba(0, 0, 0, 0.1)',
    },
    hoverCss: {
      width: '5px',
      background: '1px solid rgba(102, 194, 255, 0.5)',
    },
    grabberSize: '2rem',
  };

  return (
    <SplitPane
      split='vertical'
      minSizes={350}
      initialSizes={[1, 2]}
      resizerOptions={resizerOptions}
      className={classes.splitPane}
    >
      <Paper square className={classes.paperHeight} >
        <SetsPanel
          resetCasePage={() => handlePage(0)}
          setCurrentSetId={setCurrentSetId}
          getBreadcrumbs={props.getBreadcrumbs}
          currentSetId={currentSetId}
          currentProjectId={props.currentProjectId}
          authKey={props.authKey}
          setCurrentCaseId={setCurrentCaseId}/>
      </Paper>
      <Paper square className={classes.paperHeight} >
        <CaseEditor
          page={casePage}
          setPage={handlePage}
          currentSetId={currentSetId}
          getBreadcrumbs={props.getBreadcrumbs}
          currentCaseId={currentCaseId}
          currentProjectId={props.currentProjectId}
          setCurrentCaseId={setCurrentCaseId}
          authKey={props.authKey}/>
      </Paper>
    </SplitPane>
  );
};

export default SetsSection;