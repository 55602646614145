import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import backendUrl from "../../globalVars";
import {Button, DialogContentText} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {GridCellParams, GridColDef, DataGrid, ValueFormatterParams} from "@material-ui/data-grid";
import Pagination from "@material-ui/lab/Pagination";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DialogActions from "@material-ui/core/DialogActions";
import EditIcon from "@material-ui/icons/Edit";
import JsonForm from "../../FormCreator/JsonForm";
import paramSchema from "../../json_shema/paramSchema/paramSchema.json";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    configsTableStyle: {
      overflow: 'auto',
      height: 'calc(100vh - 112px)',
    },
    tableStyle: {
      height: 'calc(100vh - 112px)',
    },
    paginationStyle: {
      paddingRight: 10
    }
  }),
);

interface Param {
    name: string,
    comment?: string,
    isOnStartOnly: boolean
};

const getParams = async (basicAuthToken: string, currentProjectId: string, setParams: Function) => {
  const url = backendUrl + "/projects/" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const project = await response.json();

    setParams(
      project.params ?
        project.params.map((item: { name: string, type: string, comment: string, isOnStartOnly: boolean }) => {
          return {
            id: item.name,
            name: item.name,
            comment: item.comment,
            isOnStartOnly: item.isOnStartOnly
          }
        }) : []
    )
  }
};

const addParam = async (formSubmitContext: { basicAuthToken: string, currentProjectId: string, setParams: Function }, param: Param) => {
  const url = backendUrl + "/projects/" + formSubmitContext.currentProjectId;

  const { name, comment, isOnStartOnly } = param;
  const newParam = { name, comment, isOnStartOnly };

  const responseProject = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken
    })
  });

  if (responseProject.ok) {
    const { params } = await responseProject.json();
    const newParams = params ? [...params, newParam] : [newParam];

    const responseParam = await fetch(url, {
      method: "PUT",
      headers: new Headers({
        "Authorization": "Basic " + formSubmitContext.basicAuthToken,
        "Content-Type": "application/json"
      }),
      body: JSON.stringify({ params: newParams })
    });

    if (responseParam.ok) {
      getParams(formSubmitContext.basicAuthToken, formSubmitContext.currentProjectId, formSubmitContext.setParams)
    }
  }
};

const updateParam = async (formSubmitContext: { basicAuthToken: string, editingParamName: string, setParams: Function, currentProjectId: string }, param: Param) => {
  const url = backendUrl + "/projects/" + formSubmitContext.currentProjectId;

  const { name, comment, isOnStartOnly } = param;
  const newParam = { name, comment, isOnStartOnly };

  const responseProject = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken
    })
  });

  if (responseProject.ok) {
    const { params } = await responseProject.json();
    const newParams = params ? [...params.filter((param: Param) => param.name !== newParam.name), newParam] : [newParam]

    const responseParam = await fetch(url, {
      method: "PUT",
      headers: new Headers({
        "Authorization": "Basic " + formSubmitContext.basicAuthToken,
        "Content-Type": "application/json"
      }),
      body: JSON.stringify({ params: newParams })
    });

    if (responseParam.ok) {
      getParams(formSubmitContext.basicAuthToken, formSubmitContext.currentProjectId, formSubmitContext.setParams)
    }
  }
};

const deleteParam = async (basicAuthToken: string, deletingParamName: string, currentProjectId: string) => {
  const url = backendUrl + "/projects/" + currentProjectId;

  const responseProject = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (responseProject.ok) {
    const { params } = await responseProject.json();
    const newParams = params ? params.filter((param: Param) => param.name !== deletingParamName) : undefined;

    await fetch(url, {
      method: "PUT",
      headers: new Headers({
        "Authorization": "Basic " + basicAuthToken,
        "Content-Type": "application/json"
      }),
      body: JSON.stringify({ params: newParams })
    });
  }
};

function CustomPagination(props: any) {
  const { state, api } = props;

  const classes = useStyles();

  return (
    <div style={{display: "flex"}}>
      <Toolbar style={{justifyContent: "flex-end"}}>
        <Pagination className={classes.paginationStyle}
                    style={{justifyContent: "flex-start"}}
                    color="secondary"
                    count={state.pagination.pageCount}
                    onChange={(event, value) => api.current.setPage(value - 1)}/>
        <IconButton color="inherit" edge={'start'} onClick={() => props.handleClickModal('addParam')}>
          <AddBoxIcon/>
        </IconButton>
        <Dialog open={props.openModal.addParam}
                onClose={() => props.handleClickModal('addParam')}
                aria-labelledby="form-dialog-title">
          <AppBar style={{position: 'relative'}}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => props.handleClickModal('addParam')}
                          aria-label="close">
                <CloseIcon/>
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <JsonForm schema={paramSchema}
                      open={props.openModal.addParam}
                      formSubmitContext={{
                        "basicAuthToken": props.authKey,
                        "currentProjectId": props.currentProjectId,
                        "setParams": props.setParams
                      }}
                      handleClose={() => props.handleClickModal('addParam')}
                      onSubmit={addParam}/>
          </DialogContent>
        </Dialog>
      </Toolbar>
    </div>
  )
};

const ParamsSection = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [params, setParams] = useState([]);
  const [editingParamName, setEditingParamName] = React.useState('none');
  const [deletingParamName, setDeletingParamName] = React.useState('none');
  const [openModal, setOpenModal] = useState({
    editParam: false,
    deleteParam: false,
    addParam: false
  });
  const [editingParam, setEditingParam] = React.useState({});

  const handleClickModal = (modalName: string) => {
    setOpenModal(prevState => {
      // @ts-ignore
      return {...prevState, [modalName]: !openModal[modalName]}
    })
  };

  useEffect(() => {
    getParams(props.authKey, props.currentProjectId, setParams);
    // eslint-disable-next-line
  }, []);

  const paramsColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t("params.params_table.name"),
      headerAlign: 'center',
      flex: 0.4,
    },
    {
      field: 'comment',
      headerName: t("params.params_table.comment"),
      headerAlign: 'center',
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'isOnStartOnly',
      headerName: t("params.params_table.start_only"),
      headerAlign: 'center',
      disableColumnMenu: true,
      width: 140,
      align: 'center',
      valueFormatter: (params: GridCellParams) => {
        return !!params.value ? 'yes' : 'no'
      },
    },
    {
      field: 'actions',
      headerName: t("params.params_table.actions"),
      headerAlign: 'center',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      renderCell: (params: ValueFormatterParams) => (
        <Toolbar>
          <IconButton color="inherit" aria-label="menu" onClick={() => {

            setEditingParamName(params.row.name);

            // @ts-ignore
            const obj = {...params.row};
            // @ts-ignore
            setEditingParam(obj)

            handleClickModal('editParam');
          }}>
            <EditIcon/>
          </IconButton>
          <Dialog open={openModal.editParam}
                  onClose={() => {
                    handleClickModal('editParam')
                  }}
                  aria-labelledby="form-dialog-title"
                  maxWidth='md'>
            <AppBar style={{position: 'relative'}}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => handleClickModal('editParam')}
                            aria-label="close">
                  <CloseIcon/>
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <JsonForm schema={paramSchema}
                        record={{
                          ...editingParam
                        }}
                        formSubmitContext={{
                          "basicAuthToken": props.authKey,
                          "setParams": setParams,
                          "editingParamName": editingParamName,
                          "currentProjectId": props.currentProjectId
                        }}
                        open={openModal.editParam}
                        onSubmit={updateParam}
                        handleClose={() => {
                          handleClickModal('editParam')
                        }}/>
            </DialogContent>
          </Dialog>
          <IconButton color="inherit" aria-label="menu" onClick={() => {
            setDeletingParamName(params.row.id.toString());
            handleClickModal('deleteParam');
          }}>
            <DeleteForeverIcon/>
          </IconButton>
          <Dialog open={openModal.deleteParam}
                  onClose={() => handleClickModal('deleteParam')}
                  aria-labelledby="form-dialog-title">
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete selected items?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                handleClickModal('deleteParam')
              }}
                      color="primary">
                Disagree
              </Button>
              <Button onClick={async () => {
                await deleteParam(props.authKey, deletingParamName, props.currentProjectId);
                await getParams(props.authKey, props.currentProjectId, setParams);

                handleClickModal('deleteParam');
              }}
                      color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      )
    }
  ];

  return (
    <div className={classes.tableStyle}>
      <DataGrid rows={params}
                density='compact'
                className={classes.configsTableStyle}
                pageSize={20}
                hideFooterSelectedRowCount={true}
                columns={paramsColumns.map((column: GridColDef) => ({
                  ...column
                }))}
                filterModel={{
                  items: [
                    {columnField: 'name', operatorValue: 'contains', value: ''},
                  ],
                }}
                components={{
                  Pagination: (gridProps) => {
                    return <CustomPagination {...gridProps}
                                             openModal={openModal}
                                             handleClickModal={handleClickModal}
                                             authKey={props.authKey}
                                             setParams={setParams}
                                             currentProjectId={props.currentProjectId}/>
                  }
                }}/>
    </div>
  )
};

export default ParamsSection;