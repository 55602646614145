import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { GridColDef, DataGrid, GridRowParams, ValueFormatterParams, GridSortModel } from "@material-ui/data-grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import JsonForm from "../../../../FormCreator/JsonForm";
import caseSchema from "../../../../json_shema/existingCaseSchema/caseSchema.json";
import caseUiSchema from "../../../../json_shema/existingCaseSchema/caseUiSchema.json";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import backendUrl from "../../../../globalVars";
import { Button, DialogContentText, IconButton, TextField, Toolbar } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import stepSchema from "../../../../json_shema/stepSchema/stepSchema.json";
import stepUiSchema from "../../../../json_shema/stepSchema/stepUiSchema.json";
import addCaseSchema from "../../../../json_shema/addCaseSchema/addCaseSchema.json";
import Pagination from '@material-ui/lab/Pagination';
import AddBoxIcon from "@material-ui/icons/AddBox";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {AlertProps} from "@material-ui/lab/Alert";
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import MicIcon from '@material-ui/icons/Mic';
import useRecorder from "../../../useRecorder";
import { DateTime } from 'luxon';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      backgroundColor: theme.palette.background.paper
    },
    formStyle: {
      padding: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginLeft: '5px',
      borderRadius: 0,
    },
    casesTableStyle: {
      height: 'calc(100vh - 112px)',
      marginLeft: '5px',
      borderRadius: 0,
    },
    stepsTableStyle: {
      height: 'calc(100vh - 158px)',
      marginLeft: '5px',
      borderRadius: 0,
    },
    import: {
      display: 'none',
    },
    input: {
      height: '30px',
      backgroundColor: 'white',
      padding: '0 10px',
      margin: '20px 8px'
    },
    clickableTitle: {
      cursor: 'pointer',
    },
    paginationStyle: {
      paddingRight: 10
    }
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  )
};

interface Case {
  id: string,
  name: string,
  index: number,
  description: string,
  params?: {
    name: string,
    value: any,
    isOnStartOnly: boolean
  }[]
};

const getSetCases = async (basicAuthToken: string, currentSetId: string, setCases: Function, setLastIndex: Function) => {
  const url = backendUrl + "/cases?setId=" + currentSetId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const cases = await response.json();

    cases.sort((a: { index: number }, b: { index: number }) => {
      return a.index - b.index;
    })

    setLastIndex(cases.length)

    setCases(
      cases.map((item: { caseId: string, stepsCount: number, name: string, index: number, description: string, type: string, updatedAt: string, createdAt: string, params: { name: string, value: any, isOnStartOnly: boolean }[] | undefined }) => {
        return {
          id: item.caseId,
          stepsCount: item.stepsCount,
          index: item.index + 1,
          name: item.name,
          type: item.type,
          description: item.description,
          created: item.createdAt,
          updated: item.updatedAt,
          params: item.params !== undefined ?
            item.params.map(param => {
              return {
                name: param.name,
                value: param.value,
                isOnStartOnly: param.isOnStartOnly
              }
            }) : undefined
        }
      })
    )
  }
};

const addCase = async (formSubmitContext: { basicAuthToken: string, currentSetId: string, setCases: Function, setLastIndex: Function }, newCase: Case) => {
  const url = backendUrl + "/cases";

  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(newCase)
  });

  if (response.status === 201) {
    getSetCases(formSubmitContext.basicAuthToken, formSubmitContext.currentSetId, formSubmitContext.setCases, formSubmitContext.setLastIndex)
  }
};

interface UpdateCase {
  setId: string,
  name: string,
  index: number,
  description: string,
  type: string,
  params?: {
    name: string,
    value: any
  }
};

const updateCase = async (basicAuthToken: string, currentCaseId: string, updatingCase: UpdateCase, openSnackbarSuccess: Function) => {
  const url = backendUrl + "/cases/" + currentCaseId;

  await fetch(url, {
    method: "PUT",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(updatingCase)
  });

  openSnackbarSuccess('Case successfully updated!')
};

const getParamsList = async (basicAuthToken: string, currentProjectId: string, setParamsList: Function) => {
  const url = backendUrl + "/projects/" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const project = await response.json();

    setParamsList(
      project.params !== undefined ?
        project.params.map((item: { name: string, isOnStartOnly: boolean }) => {
          return {
            name: item.name,
            isOnStartOnly: item.isOnStartOnly
          }
        }) : []
    )
  }
};

const getCaseSteps = async (basicAuthToken: string, currentCaseId: string, setSteps: Function, setLastIndex: Function) => {
  const url = backendUrl + "/steps?caseId=" + currentCaseId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const stepsList = await response.json();

    stepsList.sort((a: { index: number }, b: { index: number }) => {
      return a.index - b.index;
    })

    setLastIndex(stepsList.length)

    setSteps(
      stepsList.map((item: { stepId: string, comment: string, index: number, name: string, utterance: string, reference: string, recordingFile: string }) => {
        return {
          id: item.stepId,
          index: item.index + 1,
          name: item.name,
          comment: item.comment,
          utterance: item.utterance,
          reference: item.reference,
          recordingFile: item.recordingFile
        }
      })
    )
  }
};

interface Step {
  caseId: string,
  name: string,
  index: number,
  utterance: string,
  reference: string,
  comment: string
}

const addStep = async (formSubmitContext: { basicAuthToken: string, setSteps: Function, setLastIndex: Function, currentFile: File | null }, newStep: Step) => {
  const url = backendUrl + "/steps";

  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(newStep)
  });

  if (response.status === 201) {
    getCaseSteps(formSubmitContext.basicAuthToken, newStep.caseId, formSubmitContext.setSteps, formSubmitContext.setLastIndex)
  }
};

const getVoiceLine = async (basicAuthToken: string, name: string, setCurrentFile: Function, setCurrentFileUrl: Function) => {
  if (name) {
    const url = backendUrl + "/audio?name=" + encodeURI(name).replace("(", "%28").replace(")", "%29");

    const response = await fetch(url, {
      method: "GET",
      headers: new Headers({
        "Authorization": "Basic " + basicAuthToken
      })
    });

    if (response.status === 200) {
      const voiceLine = await response.blob();
      const objectURL = URL.createObjectURL(voiceLine);

      setCurrentFile(voiceLine);
      setCurrentFileUrl(objectURL)
    }
  }
};

const deleteVoiceLine = async (basicAuthToken: string, name: string, currentCaseId: string, setSteps: Function, setLastIndex: Function) => {
  const url = backendUrl + "/audio?name=" + name;

  await fetch(url, {
    method: "DELETE",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken,
      "Content-Type": "application/json"
    })
  })
};

// @ts-ignore
const postVoiceLine = async (basicAuthToken: string, file: File | null, fileName: string, editingStepRecord, setEditingStepRecord) => {
  if (file) {
    const url = backendUrl + "/audio";
    
    let formData = new FormData();

    formData.append('audio-file', new Blob([file], {type:"multipart/form-data"}), fileName);

    await fetch(url, {
      method: "PUT",
      headers: new Headers({
        "Authorization": "Basic " + basicAuthToken
      }),
      body: formData
    })
    .then(res => res.json())
    .then(data => {
      setEditingStepRecord({...editingStepRecord, recordingFile: data})})
  }
};

interface UpdateStep {
  caseId: string,
  index: number,
  name: string,
  utterance: string,
  reference: string,
  recordingFile: string
};

const updateCaseStep = async (formSubmitContext: { basicAuthToken: string, editingStepId: string, currentCaseId: string, setSteps: Function, setLastIndex: Function }, caseStep: UpdateStep) => {
  const url = backendUrl + "/steps/" + formSubmitContext.editingStepId;

  const response = await fetch(url, {
    method: "PUT",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(caseStep)
  });

  if (response.status === 200) {
    getCaseSteps(formSubmitContext.basicAuthToken, formSubmitContext.currentCaseId, formSubmitContext.setSteps, formSubmitContext.setLastIndex)
  }
};

const deleteCaseStep = async (basicAuthToken: string, stepId: string) => {
  const url = backendUrl + "/steps";

  await fetch(url + '/' + stepId, {
    method: "DELETE",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken,
      "Content-Type": "application/json"
    })
  })
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
};

function CustomCasesPagination(props: any) {
  const { state } = props;

  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <Toolbar>
        <Pagination className={classes.paginationStyle}
          color="secondary"
          page={props.page + 1}
          count={state.pagination.pageCount}
          onChange={(event, value) => props.setPage(value - 1)} />
        <IconButton color="inherit" edge={'start'} onClick={() => props.handleClickModal('addCase')}>
          <AddBoxIcon />
        </IconButton>
        <Dialog open={props.openModal.addCase}
          onClose={() => props.handleClickModal('addCase')}
          aria-labelledby="form-dialog-title">
          <DialogContent>
            <JsonForm schema={addCaseSchema}
              open={props.openModal.addCase}
              formSubmitContext={{
                "basicAuthToken": props.authKey,
                "currentSetId": props.currentSetId,
                "setCases": props.setCases,
                "setLastIndex": props.setLastIndex
              }}
              handleClose={() => props.handleClickModal('addCase')}
              onSubmit={addCase}
              record={{
                setId: props.currentSetId,
                name: ' ' + (props.lastIndex).toString() + ' ',
                index: props.lastIndex
              }} />
          </DialogContent>
        </Dialog>
      </Toolbar>
    </div>
  )
};

function CustomStepsPagination(props: any) {
  const { state, api } = props;

  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <Toolbar>
        <Pagination className={classes.paginationStyle}
          color="secondary"
          count={state.pagination.pageCount}
          onChange={(event, value) => api.current.setPage(value - 1)} />
        <IconButton color="inherit" edge={'start'} onClick={() => props.handleClickModal('addStep')}>
          <AddBoxIcon />
        </IconButton>
        <Dialog open={props.openModal.addStep}
                       onClose={() => {
                        props.setEditingStepRecord({});
                        props.handleClickModal('addStep')
                      }}
                      fullWidth
                      maxWidth="md">
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => {
                props.setEditingStepRecord({});
                props.handleClickModal('addStep')
              }}
                aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <JsonForm schema={stepSchema}
                      uiSchema={stepUiSchema}
                      open={props.openModal.addStep}
                      formSubmitContext={{
                        "basicAuthToken": props.authKey,
                        "setSteps": props.setSteps,
                        "setLastIndex": props.setLastIndex
                      }}
                      handleClose={() => {
                        props.handleClickModal('addStep')
                      }}
                      onSubmit={addStep}
                      record={{
                        caseId: props.currentCaseId,
                        index: props.lastIndex,
                        recordingFile: props.recordingFile
                      }}/>
          </DialogContent>
        </Dialog>
      </Toolbar>
    </div>
  )
};

const DeleteDialog = (props: any) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open}
            onClose={() => props.onClose()}
            aria-labelledby="form-dialog-title">
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => props.onClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("common.delete")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}
          color="primary">
          {t("common.disagree")}
        </Button>
        <Button onClick={async () => {
          props.onAgree();
          props.onClose()
        }}
          color="primary">
          {t("common.agree")}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CaseEditor = (props: any) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [cases, setCases] = React.useState([]);
  const [caseRecord, setCaseRecord] = useState({});
  const [value, setValue] = React.useState(0);
  const [deletingStep, setDeletingStep] = React.useState('none');
  const [editingStepId, setEditingStepId] = React.useState('none');
  const [editingStepName, setEditingStepName] = React.useState('');
  const [lastCaseIndex, setLastCaseIndex] = React.useState(0);
  const [lastStepIndex, setLastStepIndex] = React.useState(0);
  const [editingStepRecord, setEditingStepRecord] = React.useState({});
  const [steps, setSteps] = React.useState([]);
  const [openModal, setOpenModal] = useState({
    editStep: false,
    delete: false,
    deleteAudio: false,
    addCase: false,
    addStep: false
  });
  const [paramsList, setParamsList] = useState([]);

  const dateValueFormatter = (params: ValueFormatterParams) => {
    return DateTime
      .fromISO(params.value as string)
      .toLocal()
      .toFormat('dd.MM.yy HH:mm');
  };

  const longTextRenderer = (params: ValueFormatterParams) => {
    const makeTitle = (): string => {
      return String(params.value).length > 25
        ? String(params.value)
        : '';
    }

    return (
      <>
        <Tooltip title={makeTitle()} placement='bottom-start' enterDelay={1300}>
          <Typography>{params.value as string}</Typography>
        </Tooltip>
      </>
    ); 
  };

  const AudioButtons = (open: boolean) => {
    let [recordingFile, recordingURL, isRecording, startRecording, stopRecording, clearRecording] = useRecorder();

    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const [currentFileUrl, setCurrentFileUrl] = useState<string>("");
    // @ts-ignore
    const [currentFileName, setCurrentFileName] = useState(editingStepRecord.recordingFile || '');
    const [recordingFileName, setRecordingFileName] = useState('voice');
    const [openAudioDialog, setOpenAudioDialog] = useState({
      create: false,
      listen: false,
    });

    useEffect(() => {
      // @ts-ignore
      getVoiceLine(props.authKey, editingStepRecord.recordingFile, setCurrentFile, setCurrentFileUrl)
      // eslint-disable-next-line
    }, [editingStepRecord]);

    useEffect(() => {
      if (!open) {
        setCurrentFile(null);
        setCurrentFileUrl("");
        setCurrentFileName("");
      }
      // @ts-ignore
    }, [open]);

    useEffect(() => {
      if (recordingFile) {
        // @ts-ignore
        setCurrentFile(recordingFile);
        // @ts-ignore
        setCurrentFileUrl(recordingURL)
      }
    }, [recordingFile, recordingURL]);

    // @ts-ignore
    const onCloseCreate = () => {
      setRecordingFileName('voice')
      // @ts-ignore
      clearRecording();
      setOpenAudioDialog({...openAudioDialog, create: false});
    };

    const onCloseListen = () => {
      setOpenAudioDialog({...openAudioDialog, listen: false});
    };

    return (
        <div style={{display: "flex", padding: "0 8px", height: 50, width: "20%"}}>
              <Tooltip title="Record">
                <IconButton  color="inherit" onClick={() => {
                    setOpenAudioDialog({...openAudioDialog, create: true});
                  }}>
                  <MicIcon />
                </IconButton>
              </Tooltip>

              <input name="file"
                     id="file"
                     type="file"
                     className={classes.import}
                     onChange={(e) => {
                      // @ts-ignore
                      if (e.target?.files) {
                        postVoiceLine(props.authKey, e.target.files[0], e.target.files[0].name.replace(' ', '_'), editingStepRecord, setEditingStepRecord)
                      }
                     }}
                     accept={'.wav, .mp3'}
              />
              <label htmlFor="file">
                <Tooltip title="Upload">
                  <IconButton component="span" color="inherit">
                    <AudioFileIcon />
                  </IconButton>
                </Tooltip>
              </label>
            
            <Dialog open={openAudioDialog.create}
                    onClose={() => onCloseCreate()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm">
            <AppBar style={{ position: 'relative' }}>
              <Toolbar>
                <IconButton color="inherit"
                            onClick={() => onCloseCreate()}
                            aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography style={{width: '100%'}}>
                  {//@ts-ignore 
                    currentFileName}
                  </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              {<TextField label={'Name'}
                     style={{width: "100%", marginBottom: 8}}
                     variant="outlined"
                     value={recordingFileName}
                     //@ts-ignore
                     onChange={e => setRecordingFileName(e.target.value.replace(' ', '_'))}/>}
                      <div style={{display: "flex"}}>
                        <audio style={{width: "60%"}} 
                               //@ts-ignore
                               src={recordingURL} 
                               controls />
                          {// eslint-disable-next-line
                            (isRecording?
                              <Button style={{width: "40%"}} color='primary' onClick={() => {
                                // @ts-ignore
                                stopRecording()
                              }}>
                                {t("sets.stop_recording")}
                              </Button>:
                              <Button style={{width: "40%"}} color='primary' onClick={() => {
                                // @ts-ignore
                                startRecording()
                              }}>
                                {t("sets.start_recording")}
                              </Button>)}
                        </div>
            </DialogContent>
            <DialogActions style={{paddingTop: 0}}>
              <>
                <Button disabled={!currentFile} onClick={() => {
                    postVoiceLine(props.authKey, currentFile, recordingFileName, editingStepRecord, setEditingStepRecord)
                      .then(() => {onCloseCreate()})
                    }}
                    color="primary">
                    {t("common.save")}
                </Button>
              </>
          </DialogActions>
          </Dialog>

          <Dialog open={openAudioDialog.listen}
                  onClose={() => onCloseListen()}
                  aria-labelledby="form-dialog-title"
                  fullWidth
                  maxWidth="sm">
            <AppBar style={{ position: 'relative' }}>
              <Toolbar>
                <IconButton color="inherit"
                            onClick={() => onCloseListen()}
                            aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography style={{width: '100%'}}>
                  {//@ts-ignore 
                    editingStepRecord.recordingFile}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <div style={{display: "flex"}}>
                <audio style={{width: "100%"}} 
                  //@ts-ignore
                  src={currentFileUrl} 
                  controls />
              </div>
            </DialogContent>
            <DialogActions style={{paddingTop: 0}}>
            </DialogActions>
          </Dialog>

          <Tooltip //@ts-ignore
                   title={editingStepRecord.recordingFile || 'no file'}>
            <IconButton 
              // @ts-ignore
              disabled={!currentFileUrl}
              onClick={() => {
                setOpenAudioDialog({...openAudioDialog, listen: true})
              }}
              color="inherit">
              <HeadsetMicIcon />
            </IconButton>
          </Tooltip>

          <div>
            <Tooltip title="Delete">
              <IconButton color="inherit" onClick={() => handleClickModal('deleteAudio')}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
            <DeleteDialog // @ts-ignore
                          disabled={!editingStepRecord.recordingFile}
                          onClose={() => handleClickModal('deleteAudio')} 
                          onAgree={() => {
                            // @ts-ignore
                            deleteVoiceLine(props.authKey, editingStepRecord.recordingFile, props.currentCaseId, setSteps, setLastStepIndex)
                              .then(() => {
                                setEditingStepRecord({...editingStepRecord, recordingFile: ''});
                              })
                              .then(() => {
                                setCurrentFile(null);
                                setCurrentFileUrl('');
                                setCurrentFileName('');
                              })
                          }} 
                          open={openModal.deleteAudio}/>
          </div>
      </div>
    )
  };

  const stepsColumns: GridColDef[] = [
    {
      field: 'index',
      headerName: '#',
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      width: 70
    },
    {
      field: 'name',
      headerName: t("sets.cases_table.instructions"),
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: longTextRenderer,
    },
    {
      field: 'utterance',
      headerName: t("sets.cases_table.utterance"),
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: longTextRenderer,
    },
    {
      field: 'reference',
      headerName: t("sets.cases_table.reference"),
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: longTextRenderer,
    },
    {
      field: 'comment',
      headerName: t("sets.cases_table.comment"),
      headerAlign: 'center',
      sortable: false,
      flex: 1,
      renderCell: longTextRenderer,
    },
    {
      field: 'actions',
      headerName: ' ',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: ValueFormatterParams) => (
        <Toolbar>
          <IconButton color="inherit" aria-label="menu" onClick={() => {
            setEditingStepName(params.row.index);
            setEditingStepId(params.row.id.toString());

            // @ts-ignore
            const obj = {...params.row};
            // @ts-ignore
            delete obj.id;
            // @ts-ignore
            obj.index = obj.index - 1;
            // @ts-ignore
            setEditingStepRecord(obj);

            handleClickModal('editStep');
            }}>
            <EditIcon />
            </IconButton>
            <Dialog open={openModal.editStep}
                  onClose={() => {
                      setEditingStepRecord({});
                      handleClickModal('editStep');
                    }}
                    fullWidth
                    maxWidth="md">
            <AppBar style={{ position: 'relative' }}>
              <Toolbar>
                <IconButton color="inherit" onClick={() => {
                  setEditingStepRecord({});
                  handleClickModal('editStep')}}
                  aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography style={{width: '100%', textAlign: "center"}}>
                  {editingStepName}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <JsonForm schema={stepSchema}
                uiSchema={stepUiSchema}
                record={{
                  ...editingStepRecord,
                  caseId: props.currentCaseId
                }}
                formSubmitContext={{
                  "basicAuthToken": props.authKey,
                  "editingStepId": editingStepId,
                  "currentCaseId": props.currentCaseId,
                  "setSteps": setSteps,
                  "setLastIndex": setLastStepIndex,
                }}
                open={openModal.editStep}
                onSubmit={updateCaseStep}
                import
                fieldFeatures={true}
                fieldFeaturesFields={{
                  'utterance': AudioButtons(openModal.editStep)
                }}
                handleClose={() => {
                  setEditingStepRecord({});
                  handleClickModal('editStep');
                }} />
            </DialogContent>
            </Dialog>
          <IconButton color="inherit" aria-label="menu" onClick={() => {
            setDeletingStep(params.row.id.toString());
            handleClickModal('delete');
          }}>
            <DeleteForeverIcon />
          </IconButton>
          <DeleteDialog onClose={() => handleClickModal('delete')} onAgree={async () => {
            await deleteCaseStep(props.authKey, deletingStep);
            await getCaseSteps(props.authKey, props.currentCaseId, setSteps, setLastStepIndex);
          }} open={openModal.delete}/>
        </Toolbar>
      )
    }
  ];

  const onRowClicked = (params: GridRowParams) => {
    props.setCurrentCaseId(params.row.id.toString());
    props.getBreadcrumbs({ ...props.breadcrumbs, currentCase: String(params.row.index) });
    // @ts-ignore
    const obj = { ...params.row };
    // @ts-ignore
    delete obj.id;
    // @ts-ignore
    delete obj.stepsCount;
    // @ts-ignore
    obj.name = ' ' + obj.name + ' ';
    // @ts-ignore
    obj.index = obj.index - 1;
    // @ts-ignore
    setCaseRecord(obj);
    setValue(0);
  };

  const casesColumns: GridColDef[] = [
    {
      field: 'index',
      headerName: '#',
      headerAlign: 'center',
      filterable: false,
      disableColumnMenu: true,
      align: 'center',
      width: 70,
      renderCell: (params: ValueFormatterParams) => (
        <><strong>{params.value}</strong></>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'type',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.2
    },
    {
      field: 'stepsCount',
      headerName: 'Steps',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: 'created',
      headerName: 'Created',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      valueFormatter: dateValueFormatter,
    },
    {
      field: 'updated',
      headerName: 'Updated',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      valueFormatter: dateValueFormatter,
    },
  ];

  const handleClickModal = (modalName: string) => {
    setOpenModal(prevState => {
      // @ts-ignore
      return { ...prevState, [modalName]: !openModal[modalName] }
    })
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.currentCaseId !== 'none') {
      getCaseSteps(props.authKey, props.currentCaseId, setSteps, setLastStepIndex)
    }
    // eslint-disable-next-line
  }, [props.currentCaseId]);

  useEffect(() => {
    if (props.currentSetId !== 'none') {
      getSetCases(props.authKey, props.currentSetId, setCases, setLastCaseIndex);

      if (paramsList.length === 0) {
        getParamsList(props.authKey, props.currentProjectId, setParamsList)
      }
    }

    // eslint-disable-next-line
  }, [props.currentSetId]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');

  const openSnackbarSuccess = (text: string) => {
    setSnackbarOpen(true);
    setSnackbarText(text)
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const stepsSortModel: GridSortModel = [
    {
      field: 'index',
      sort: 'asc',
    },
  ];

  if (props.currentCaseId !== 'none') {
    return (
      <React.Fragment>
        <IconButton onClick={() => {
            props.getBreadcrumbs({...props.breadcrumbs, currentCase: ''});
            props.setCurrentCaseId("none")
          }} style={{position: "absolute", zIndex: 300}}>
          <ArrowBackIcon/>
        </IconButton>
        <Tabs className={classes.tabs}
          value={value}
          onChange={handleChange}
          centered
          indicatorColor="primary">
          <Tab label="Steps" {...a11yProps(0)} />
          <Tab label="General" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={classes.stepsTableStyle}>
            <DataGrid
              columns={stepsColumns}
              rows={steps}
              density='compact'
              pageSize={20}
              sortModel={stepsSortModel}
              hideFooterSelectedRowCount={true}
              components={{
                Pagination: (gridProps) => {
                  return <CustomStepsPagination {...gridProps}
                    // @ts-ignore
                    recordingFile={editingStepRecord.recordingFile}
                    setEditingStepRecord={setEditingStepRecord}
                    AudioButtons={AudioButtons}
                    handleClickModal={handleClickModal}
                    openModal={openModal}
                    authKey={props.authKey}
                    currentCaseId={props.currentCaseId}
                    setSteps={setSteps}
                    lastIndex={lastStepIndex}
                    setLastIndex={setLastStepIndex} />
                }
              }} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.formStyle}>
            <Snackbar open={snackbarOpen} autoHideDuration={1000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
              <Alert onClose={handleSnackbarClose} severity="success">
                {snackbarText}
              </Alert>
            </Snackbar>
            <JsonForm style={{ height: 'calc(100vh - 198px)'}}
                      schema={caseSchema}
                      objectKeysSource={{
                        params: paramsList
                      }}
                      buttons={(formData: any, errors: any) => {
                        return (
                          <Button onClick={async () => {
                            const notSubmittable: boolean = Object.entries(errors).some((error) => error[1] === true);

                            if (notSubmittable !== true) {
                              await updateCase(props.authKey, props.currentCaseId, formData, openSnackbarSuccess);
                              await getSetCases(props.authKey, props.currentSetId, setCases, setLastCaseIndex)
                            }
                          }}
                                  color="primary">
                            Update
                          </Button>
                        )
                      }}
                      uiSchema={caseUiSchema}
                      record={{
                        ...caseRecord,
                        setId: props.currentSetId
                      }} />
          </div>
        </TabPanel>
      </React.Fragment>
    )
  } else if (props.currentSetId !== 'none') {
    return (
      <React.Fragment>
        <div className={classes.casesTableStyle}>
          <DataGrid
            columns={casesColumns}
            rows={cases}
            density='compact'
            pageSize={20}
            page={props.page}
            components={{
              Pagination: (gridProps) => {
                return <CustomCasesPagination {...gridProps}
                  setPage={props.setPage}
                  page={props.page}
                  handleClickModal={handleClickModal}
                  openModal={openModal}
                  authKey={props.authKey}
                  currentSetId={props.currentSetId}
                  setCases={setCases}
                  lastIndex={lastCaseIndex}
                  setLastIndex={setLastCaseIndex} />
              }
            }}
            disableSelectionOnClick={true}
            onRowClick={onRowClicked}
          />
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default CaseEditor;