export const translation = {
  commonRu: {
    common: {
      test_suite: "Система тестирования",
      name: "Имя",
      description: "Описание",
      comment: "Комментарий",
      submit: "Отправить",
      cancel: "Отмена",
      delete: "Данные будут безвозвратно удалены. Вы уверены?",
      change_lang: "Сменить язык: ",
      sign_in: "Вход",
      username: "Имя пользователя",
      password: "Пароль",
      save: "Сохранить",
      agree: "Уверен",
      disagree: "Отмена",
      export_as: "Экспортировать как..."
    },
    projects: {
      projects: "Проекты",
      project_table: {
        name: "Имя",
        description: "Описание",
        created: "Дата создания",
        updated: "Дата обновления"
      },
    },
    suits: {
      sets: "Сеты",
      runs: "Прогоны",
      configs: "Конфигурации",
      params: "Параметры"
    },
    sets: {
      sets_table: {
        set: "Сет",
        description: "Описание",
        cases: "Кейсы",
        steps: "Шаги"
      },
      cases_table: {
        instructions: "Инструкции",
        utterance: "Фраза",
        reference: "Эталон",
        comment: "Комментарий",
        audio: "Аудио"
      },
      start_recording: "Начать запись",
      stop_recording: "Остановить запись",
      import: "Импортировать",
      audio_import: "Импортировать аудио",
      upload_text: "Пожалуйста, загрузите файл с кейсами",
      type: "Тип",
      params: "Параметры"
    },
    runs: {
      runs_table: {
        set: "Сет",
        comment: "Комментарий",
        config: "Конфиругация",
        date: "Дата",
        tests: "Тесты",
        passed: "Пройдено",
        mode: "Режим"
      },
      run_cases_table: {
        index: "Индекс",
        session_id: "ID сессии",
        step_pass: "Пройдено",
      },
      run_steps_table: {
        step: "Шаг",
        utterance: "Фраза",
        reference: "Ожидаемый результат",
        result: "Фактический результат",
        passed: "Пройдено",
      },
      config: "Конфигурация",
      step: "Шаг",
      cases_pass: "Кейсов пройдено",
      utterance: "Фраза",
      expected_answer: "Ожидаемый ответ",
      actual_answer: "Реальный ответ",
      channel: "Выбор тестового канала"
    },
    configs: {
      config_table: {
        name: "Имя",
        api_url: "API URL",
        channel_id: "Id канала",
        formatting_type: "Тип форматирования",
        actions: "Действия"
      },
      apiUrl: "API URL",
      token: "Токен",
      channelId: "Id канала",
      channelType: "Тип канала",
      formattingType: "Тип форматирования"
    },
    params: {
      params_table: {
        name: "Имя",
        comment: "Комментарий",
        start_only: "Только при запуске",
        actions: "Действия",
      },
      isOnStartOnly: "Только при запуске"
    }
  },
  commonEn: {
    common: {
      test_suite: "Test suite",
      name: "Name",
      description: "Description",
      comment: "Comment",
      submit: "Submit",
      cancel: "Cancel",
      delete: "Are you sure you want to delete selected items?",
      change_lang: "Change language: ",
      sign_in: "Sign in",
      save: "Save",
      username: "Username",
      password: "Password",
      agree: "Agree",
      disagree: "Cancel",
      export_as: "Export as..."
    },
    projects: {
      projects: "Projects",
      project_table: {
        name: "Name",
        description: "Description",
        created: "Created",
        updated: "Updated"
      }
    },
    suits: {
      sets: "Sets",
      runs: "Runs",
      configs: "Configs",
      params: "Params"
    },
    sets: {
      sets_table: {
        set: "Set",
        description: "Description",
        cases: "Cases",
        steps: "Steps"
      },
      cases_table: {
        instructions: "Instructions",
        utterance: "Utterance",
        reference: "Reference",
        comment: "Comment",
        audio: "Audio",
      },
      start_recording: "Start recording",
      stop_recording: "Stop recording",
      import: "Import",
      audio_import: "Import audio",
      upload_text: "Please, upload file with cases",
      type: "Type",
      params: "Params"
    },
    runs: {
      runs_table: {
        set: "Set",
        comment: "Comment",
        config: "Config",
        date: "Date",
        tests: "Tests",
        passed: "Passed",
        mode: "Mode"
      },
      run_cases_table: {
        index: "Index",
        session_id: "Session ID",
        step_pass: "Passed",
      },
      run_steps_table: {
        step: "Step",
        utterance: "Utterance",
        reference: "Reference",
        result: "Result",
        passed: "Passed",
      },
      config: "Config",
      step: "Step",
      cases_pass: "Cases passed",
      utterance: "Utterance",
      expected_answer: "Expected answer",
      actual_answer: "Actual answer",
      channel: "Choose test channel"
    },
    configs: {
      config_table: {
        name: "Name",
        api_url: "API URL",
        channel_id: "Channel id",
        formatting_type: "Formatting type",
        actions: "Actions"
      },
      apiUrl: "API URL",
      token: "Token",
      channelId: "Channel id",
      channelType: "Channel type",
      formattingType: "Formatting type"
    },
    params: {
      params_table: {
        name: "Name",
        comment: "Comment",
        start_only: "Is only on start",
        actions: "Actions",
      },
      isOnStartOnly: "Is only on start"
    },
  }
}
