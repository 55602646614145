import { Button, Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  GridColDef,
  DataGrid,
  ValueFormatterParams,
  GridCellClassParams,
  GridSortModel,
  GridCellValue,
  GridRowParams,
  GridRowModel,
  GridPageChangeParams
} from "@material-ui/data-grid";
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from "react";
import backendUrl from "../../globalVars";
import Paper from '@material-ui/core/Paper';
import DialogContent from "@material-ui/core/DialogContent";
import clsx from 'clsx';
import Dialog from "@material-ui/core/Dialog";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { CheckCircleOutlineOutlined, GetAppOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ReactDiffViewer from 'react-diff-viewer';
import { SplitPane } from 'react-collapse-pane';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DateTime } from 'luxon';
import FileSaver from 'file-saver';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    searchFieldStyle: {
      margin: theme.spacing(2),
    },
    clickableTitle: {
      cursor: 'pointer',
    },
    tableToolbarStyle: {
      display: 'flex',
    },
    versionSelect: {
      minWidth: '100px',
    },
    paperStyle: {
      height: 'calc(100vh - 112px)',
    },
    splitPane: {
      height: 'calc(100vh - 112px) !important',
    },
    setsTableStyle: {
      height: 'calc(100vh - 112px)',
      overflowX: 'hidden',
    },
    casesTableStyle: {
      height: 'calc(100vh - 162px)',
      '& .step-pass.negativeBg': {
        backgroundColor: '#fff7f7',
      },
      '& .step-pass.positiveBg': {
        backgroundColor: '#ffffff',
      },
      '& .step-pass.negativeIcon': {
        color: 'red',
        fontWeight: 600,
        textAlign: 'center',
      },
      '& .step-pass.positiveIcon': {
        color: 'green',
        fontWeight: 600,
        textAlign: 'center',
      },
      marginLeft: '5px',
      borderRadius: 0,
    },
  })
);

interface RunReport {
  results: {}[];
  passedCount: number;
  failedCount: number;
}

interface RunEntity {
  runId: string;
  setId: string;
  mode: string;
  configId: string;
  name: string;
  status: string;
  error?: string;
  report?: RunReport;
  createdAt: string;
  updatedAt: string;
}

const getRuns = async (basicAuthToken: string, currentProjectId: string, setRunsRows: Function, currentRunValues: { id: string, status: string }, setCurrentRunValues: Function, sets: Object, runsPage: number, runsLimit: number, setRunsCount: Function) => {
  const url = backendUrl + "/runs?projectId=" + currentProjectId + "&startFrom=" + (runsPage * runsLimit).toString() + "&runsLimit=" + runsLimit.toString();

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const runsData = await response.json();

    setRunsCount(runsData.count);

    setRunsRows(
      runsData.runs.map((item: RunEntity) => {
        if (currentRunValues.id !== 'none') {
          if (currentRunValues.id === item.runId) {
            if (currentRunValues.status !== item.status) {
              setCurrentRunValues({
                ...currentRunValues,
                status: item.status
              })
            }
          }
        }

        const testPassedCount = item.report?.passedCount || 0;
        const testFailedCount = item.report?.failedCount || 0;
        const testCount = testPassedCount + testFailedCount;

        const passedPercent = (testPassedCount / testCount) * 100;

        return {
          id: item.runId,
          name: item.name,
          //@ts-ignore
          setName: sets[item.setId],
          mode: item.mode,
          date: item.createdAt,
          status: item.status,
          error: item.error,
          config: item.configId,
          tests: testCount || '',
          passed: Number.isFinite(passedPercent) ? `${passedPercent.toFixed()}%` : '',
        }
      })
    )
  }
};

const getRunSteps = async (basicAuthToken: string, currentProjectId: string, currentRunId: string, setRunCaseRows: Function, setRunStepRows: Function, runsPage: number, runsLimit: number) => {
  const url = backendUrl + "/runs?projectId=" + currentProjectId + "&startFrom=" + (runsPage * runsLimit).toString() + "&runsLimit=" + runsLimit.toString();

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const runsStepsList = await response.json();

    runsStepsList.runs.forEach((item: { runId: string, runCase: string, name: string, report: { results: { caseId: string, index: number, sessionId: string, passed: boolean, steps: { stepId: string, passed: boolean, actual: string, expected: string, index: number, utterance: string }[] | undefined }[] } | undefined }) => {
      if (item.runId === currentRunId) {
        setRunCaseRows(
          item.report !== undefined ?
            item.report.results.map((runCase) => {
              if (runCase.steps !== undefined) {
                return {
                  id: runCase.caseId,
                  index: runCase.index + 1,
                  stepPass: runCase.passed,
                  sessionId: runCase.sessionId
                }
              } else {
                return []
              }}).flat() : []
            );

            setRunStepRows(
              item.report !== undefined ?
                item.report.results.map((runCase) => {
                  if (runCase.steps !== undefined) {
                    return runCase.steps.map(runStep => {
                      return {
                        id: runStep.stepId,
                        caseId: runCase.caseId,
                        name: item.name,
                        stepPass: runStep.passed,
                        step: runStep.index + 1,
                        utterance: runStep.utterance,
                        expected: runStep.expected,
                        actual: runStep.actual
                      }
                    })
                  } else {
                    return []
                  }}).flat() : []
            );
      }
    })
  }
};

type ConfigMap = { [configId: string]: string };

const getConfigs = async (basicAuthToken: string, currentProjectId: string, setConfigs: Function) => {
  const url = backendUrl + "/configs?projectId=" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const configsList = await response.json();

    setConfigs(
      configsList.reduce((map: ConfigMap, item: { configId: string, name: string }) => {
        map[item.configId] = item.name;

        return map;
      }, {})
    )
  }
};

type SetsMap = { [setId: string]: string };

const getSets = async (basicAuthToken: string, currentProjectId: string, setSets: Function) => {
  const url = backendUrl + "/sets?projectId=" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const sets = await response.json();

    setSets(
      sets.reduce((map: SetsMap, item: { setId: string, name: string }) => {
        map[item.setId] = item.name;

        return map;
      }, {})
    )
  }
};

const RunsSection = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [currentRunValues, setCurrentRunValues] = useState({
    id: 'none',
    status: 'none',
    page: 0
  });
  const [currentCaseValues, setCurrentCaseValues] = useState({
    id: 'none',
    status: 'none',
    page: 0
  });
  const [currentRunStepsRecord, setCurrentRunStepsRecord] = useState<GridRowModel>();
  const [runsRows, setRunsRows] = useState([]);
  const [isStepStatusDialogOpen, setStepStatusDialogOpen] = useState<boolean>(false);
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [configs, setConfigs] = useState<ConfigMap>({});
  const [sets, setSets] = useState<SetsMap>({});
  const [runsSortModel, setRunsSortModel] = useState<GridSortModel>(
    [
      {
        field: 'date',
        sort: 'desc',
      }
    ]);
    const [runsCasesSortModel, setRunsCasesSortModel] = useState<GridSortModel>(
      [
        {
        field: 'index',
        sort: 'asc',
      }
    ]);
    const [runsStepsSortModel, setRunsStepsSortModel] = useState<GridSortModel>(
      [
        {
        field: 'step',
        sort: 'asc',
      }
    ]);
    const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
    const [runsPage, setRunsPage] = React.useState(0);
    const [runsLimit] = React.useState(20);
    const [runsCount, setRunsCount] = React.useState(0);
    const [currentRunCaseId, setCurrentRunCaseId] = React.useState('');
    const [runCaseRows, setRunCaseRows] = React.useState([]);
    const [runStepRows, setRunStepRows] = React.useState([]);

  const handleRunsPageChange = (params: GridPageChangeParams) => {
    setRunsPage(params.page);
  };

  useEffect(() => {
    getConfigs(props.authKey, props.currentProjectId, setConfigs);
    getSets(props.authKey, props.currentProjectId, setSets);
    // eslint-disable-next-line
  }, [props.authKey, props.currentProjectId])

  useEffect(() => {
    if (Object.entries(sets).length !== 0 && Object.entries(configs).length !== 0) {
      getRuns(props.authKey, props.currentProjectId, setRunsRows, currentRunValues, setCurrentRunValues, sets, runsPage, runsLimit, setRunsCount);
    };

    setLoaded(true);
    // eslint-disable-next-line
  }, [sets, configs, runsPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      getRuns(props.authKey, props.currentProjectId, setRunsRows, currentRunValues, setCurrentRunValues, sets, runsPage, runsLimit, setRunsCount);
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isLoaded, currentRunValues.id, currentRunValues.status, runsPage, sets]);

  useEffect(() => {
    getRunSteps(props.authKey, props.currentProjectId, currentRunValues.id, setRunCaseRows, setRunStepRows, runsPage, runsLimit)
    // eslint-disable-next-line
  }, [currentRunValues.id, currentRunValues.status]);

  const statusRenderer = (params: ValueFormatterParams) => {
    const status = params.row.status;
    const error = params.row.error;

    switch (status) {
      case 'Finished':
        return <><DoneIcon /></>
      case 'Launched':
        return <><CircularProgress size={23} /></>
      case 'Failed':
        return (
          <>
            <Tooltip title={<pre>{error}</pre>} enterDelay={1300}>
              <ReportProblemOutlinedIcon />
            </Tooltip>
          </>
        );
      default:
        break;
    }

    return <><HelpOutlineOutlinedIcon /></>
  };

  const testRunsColumns: GridColDef[] = [
    {
      field: 'setName',
      headerName: t("runs.runs_table.set"),
      headerAlign: 'center',
      align: 'center',
      flex: 2
    },
    {
      field: 'name',
      headerName: t("runs.runs_table.comment"),
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (params: ValueFormatterParams) =>
        <Tooltip title={<Typography>{params.row.name}</Typography>} enterDelay={300}>
          <Typography>{params.row.name}</Typography>
        </Tooltip>
    },
    {
      field: 'mode',
      headerName: t("runs.runs_table.mode"),
      headerAlign: 'center',
      align: 'center',
      flex: 2
    },
    {
      field: 'config',
      headerName: t("runs.runs_table.config"),
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      valueFormatter: (params: ValueFormatterParams) => {
        return configs[params.row.config]
      },
    },
    {
      field: 'date',
      headerName: t("runs.runs_table.date"),
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      valueFormatter: (params: ValueFormatterParams) => {
        return DateTime
          .fromISO(params.value as string)
          .toLocal()
          .toFormat('dd.MM.yy HH:mm');
      },
    },
    {
      field: 'status',
      headerName: ' ',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 70,
      renderCell: statusRenderer,
    },
    {
      field: 'tests',
      headerName: t("runs.runs_table.tests"),
      headerAlign: 'center',
      flex: 2,
    },
    {
      field: 'passed',
      headerName: t("runs.runs_table.passed"),
      headerAlign: 'center',
      flex: 2,
    },
  ];

  const stepComparator = (v1: GridCellValue, v2: GridCellValue) => {
    // @ts-ignore
    if (v1.length < 4) {
      return Number(v1) - Number(v2);
    } else {
      // @ts-ignore
      if (parseInt(v1, 10) === parseInt(v2, 10)) {
        // @ts-ignore
        return Number(v1 + 1) - Number(v2 + 1)
      } else {
        return Number(v1) - Number(v2)
      }
    }
  };

  const stepRenderer = (params: ValueFormatterParams) => {
    return (
      <>
        <strong>{params.value}</strong>
      </>
    );
  };

  const casePassRenderer = (params: ValueFormatterParams) => {
    return params.value
      ? <><CheckCircleOutlineOutlined /></>
      : <><ErrorOutlineOutlined /></>;
  };

  const stepPassRenderer = (params: ValueFormatterParams) => {
    return params.value
      ? <><CheckCircleOutlineOutlined /></>
      : <><ErrorOutlineOutlined /></>;
  };

  const longTextRenderer = (params: ValueFormatterParams) => {
    const makeTitle = (): string => {
      return String(params.value).length > 25
        ? String(params.value)
        : '';
    }

    return (
      <>
        <Tooltip title={makeTitle()} placement='bottom-start' enterDelay={1300}>
          <Typography>{params.value as string}</Typography>
        </Tooltip>
      </>
    );
  };

  const getCellClassNames = (params: GridCellClassParams) => {
    return clsx('step-pass', {
      negativeBg: !params.row.stepPass,
      positiveBg: params.row.stepPass,
      negativeIcon: params.field === 'stepPass' && !params.value,
      positiveIcon: params.field === 'stepPass' && params.value,
    })
  };

  const runCasesColumns: GridColDef[] = [
    {
      field: 'index',
      headerName: t("runs.run_cases_table.index"),
      headerAlign: 'center',
      align: 'center',
      cellClassName: getCellClassNames,
      renderCell: longTextRenderer,
    },
    {
      field: 'sessionId',
      headerName: t("runs.run_cases_table.session_id"),
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      cellClassName: getCellClassNames,
      renderCell: (params: ValueFormatterParams) => (
        <>
          <Typography onClick={() => {
            props.getBreadcrumbs({ ...props.breadcrumbs, currentRunCase: String(params.row.index) });
            onCasesRowClicked(params.row.id.toString())}}>
            {params.value}
          </Typography>
          <Tooltip title="Copy">
            <IconButton onClick={() => {
              // @ts-ignore
              navigator.clipboard.writeText(params.value.toString())
            }}>
                <FileCopyIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'stepPass',
      headerName: t("runs.run_cases_table.step_pass"),
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      cellClassName: getCellClassNames,
      renderCell: casePassRenderer,
    },
  ];

  const testRunStepsColumns: GridColDef[] = [
    {
      field: 'step',
      headerName: t("runs.run_steps_table.step"),
      headerAlign: 'center',
      align: 'center',
      width: 110,
      cellClassName: getCellClassNames,
      sortComparator: stepComparator,
      renderCell: stepRenderer,
    },
    {
      field: 'utterance',
      headerName: t("runs.run_steps_table.utterance"),
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      cellClassName: getCellClassNames,
      renderCell: longTextRenderer,
    },
    {
      field: 'expected',
      headerName: t("runs.run_steps_table.reference"),
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      cellClassName: getCellClassNames,
      renderCell: longTextRenderer,
    },
    {
      field: 'actual',
      headerName: t("runs.run_steps_table.result"),
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      cellClassName: getCellClassNames,
      renderCell: longTextRenderer,
    },
    {
      field: 'stepPass',
      headerName: t("runs.run_steps_table.passed"),
      headerAlign: 'center',
      align: 'center',
      width: 125,
      cellClassName: getCellClassNames,
      renderCell: stepPassRenderer,
    },
  ];

  const toggleStepStatusDialog = () => {
    setStepStatusDialogOpen(!isStepStatusDialogOpen);
  };

  const onRunRowClicked = (params: GridRowParams) => {
    props.getBreadcrumbs({ ...props.breadcrumbs, currentRun: String(params.row.name) });

    setCurrentRunValues(
      {
        id: params.row.id.toString(),
        status: params.row.status.toString(),
        page: 0
      }
    );
    setCurrentRunCaseId("")
  };

  const onCasesRowClicked = (id: string) => {
    // @ts-ignore
    setCurrentRunCaseId(id.toString());
  };

  const onStepRowClicked = (params: GridRowParams) => {
    setCurrentRunStepsRecord(params.row);
    toggleStepStatusDialog();
  };

  const onDownloadMenuClick = (event: React.MouseEvent) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onDownloadMenuItemClick = () => {
    setMenuAnchorEl(null)
  };

  const downloadReport = async (format: 'xlsx' | 'csv' | 'html') => {
    const res = await fetch(`${backendUrl}/runs/${currentRunValues.id}/actions/export?format=${format}`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Basic ${props.authKey}`,
      })
    });

    if (res.ok) {
      FileSaver.saveAs(await res.blob(), `report.${format}`);
    }
  }

  const resizerOptions = {
    css: {
      width: '5px',
      background: 'rgba(0, 0, 0, 0.1)',
    },
    hoverCss: {
      width: '5px',
      background: '1px solid rgba(102, 194, 255, 0.5)',
    },
    grabberSize: '2rem',
  };

  return (
    <SplitPane
      split='vertical'
      minSizes={450}
      initialSizes={[1, 2]}
      resizerOptions={resizerOptions}
      className={classes.splitPane}
    >
      <Paper square className={classes.paperStyle}>
        <div className={classes.setsTableStyle}>
          <DataGrid
            columns={testRunsColumns}
            rows={runsRows}
            density='compact'
            pageSize={runsLimit}
            rowCount={runsCount}
            sortModel={runsSortModel}
            hideFooterSelectedRowCount={true}
            onRowClick={onRunRowClicked}
            pagination
            paginationMode="server"
            onPageChange={handleRunsPageChange}
            loading={!isLoaded}
            onSortModelChange={(param) => {
              setRunsSortModel(param.sortModel)
            }}
          />
        </div>
      </Paper>
      <Paper square className={classes.paperStyle}>
        <Dialog
          open={isStepStatusDialogOpen}
          onClose={toggleStepStatusDialog}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth='lg'
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={toggleStepStatusDialog}
              >
                <CloseIcon />
              </IconButton>
              <Typography style={{ marginLeft: '10px' }}>{`Step ${currentRunStepsRecord?.step}`}</Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container direction={'row'}>
              <Grid item xs={12} sm={12}>
                <Typography style={{ fontWeight: 600, marginTop: '10px' }}>Utterance:</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography style={{ marginTop: '10px' }}>{currentRunStepsRecord?.utterance}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography style={{ fontWeight: 600, marginTop: '10px' }}>Result:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ marginBottom: 30 }}>
                <br />
                <ReactDiffViewer
                  oldValue={currentRunStepsRecord?.expected}
                  newValue={currentRunStepsRecord?.actual}
                  disableWordDiff={true}
                  showDiffOnly={false}
                  leftTitle={'Reference'}
                  rightTitle={'Response'}
                  hideLineNumbers={true}
                  styles={{
                    content: {
                      // backgroundColor: '#222224'
                      //fontSize: '15px'
                    },
                    diffContainer: {
                      fontSize: '15px'
                    },
                    diffRemoved: {
                      backgroundColor: '#FFF'
                    },
                    diffAdded: {
                      backgroundColor: '#FF000011'
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <div className={classes.casesTableStyle}>
          {currentRunCaseId &&
            <IconButton onClick={() => {
              props.getBreadcrumbs({ ...props.breadcrumbs, currentRunCase: '' });
              setCurrentRunCaseId("");
              setCurrentCaseValues({...currentCaseValues, page: 0})
              }} style={{position: "absolute", zIndex: 300}}>
              <ArrowBackIcon/>
            </IconButton>
          }
          <AppBar position='static' color='transparent'>
            <Toolbar variant='dense'>
              <div style={{ flexGrow: 1 }}>&nbsp;</div>
              <Button
                disabled={currentRunValues.status !== 'Finished'}
                aria-controls="simple-menu"
                aria-haspopup="true"
                size='small'
                variant='text'
                onClick={onDownloadMenuClick}
              >
                <GetAppOutlined />{t("common.export_as")}
              </Button>
              <Menu
                id='download-menu'
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                keepMounted={true}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={onDownloadMenuItemClick}
              >
                <MenuItem
                  key='xlsx'
                  onClick={() => {
                    onDownloadMenuItemClick();
                    downloadReport('xlsx');
                  }}>
                  <Typography>XLSX</Typography>
                </MenuItem>
                <MenuItem
                  key='csv'
                  onClick={() => {
                    onDownloadMenuItemClick();
                    downloadReport('csv');
                  }}>
                  <Typography>CSV</Typography>
                </MenuItem>
                <MenuItem
                  key='html'
                  onClick={() => {
                    onDownloadMenuItemClick();
                    downloadReport('html');
                  }}>
                  <Typography>HTML</Typography>
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          {!currentRunCaseId? 
                    <DataGrid
                      columns={runCasesColumns}
                      page={currentRunValues.page}
                      onPageChange={(newPage) => setCurrentRunValues({...currentRunValues, page: newPage.page})}
                      rows={runCaseRows}
                      density='compact'
                      pageSize={20}
                      sortModel={runsCasesSortModel}
                      hideFooterSelectedRowCount={true}
                      onRowClick={(params) => {
                        props.getBreadcrumbs({ ...props.breadcrumbs, currentRunCase: String(params.row.index) });
                        onCasesRowClicked(params.row.id.toString())
                      }}
                      onSortModelChange={(param) => {
                          setRunsCasesSortModel(param.sortModel)
                      }}
                    />:
                    <DataGrid
                      columns={testRunStepsColumns}
                      page={currentCaseValues.page}
                      onPageChange={(newPage) => setCurrentCaseValues({...currentRunValues, page: newPage.page})}
                      // @ts-ignore
                      rows={runStepRows.filter(step => step.caseId === currentRunCaseId)}
                      density='compact'
                      pageSize={20}
                      sortModel={runsStepsSortModel}
                      hideFooterSelectedRowCount={true}
                      onRowClick={(params) => {
                        onStepRowClicked(params)
                      }}
                      onSortModelChange={(param) => {
                        setRunsStepsSortModel(param.sortModel)
                      }}
                  />}
        </div>
      </Paper>
    </SplitPane>
  );
};

export default RunsSection;