import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import backendUrl from "../../globalVars";
import {Button, DialogContentText} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {GridColDef, DataGrid, ValueFormatterParams} from "@material-ui/data-grid";
import Pagination from "@material-ui/lab/Pagination";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DialogActions from "@material-ui/core/DialogActions";
import EditIcon from "@material-ui/icons/Edit";
import JsonForm from "../../FormCreator/JsonForm";
import configSchema from "../../json_shema/configSchema/configSchema.json";
import configUiSchema from "../../json_shema/configSchema/configUiSchema.json";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    configsTableStyle: {
      overflow: 'auto',
      height: 'calc(100vh - 112px)',
    },
    tableStyle: {
      height: 'calc(100vh - 112px)',
    },
    paginationStyle: {
      paddingRight: 10
    }
  }),
);

interface Config {
  projectId: string,
  name: string,
  apiUrl: string,
  token: string,
  sessionId: string,
  userId: string,
  channelId: string,
  channelType: string,
  formattingType: string
};

const addConfig = async (formSubmitContext: { basicAuthToken: string, setConfigs: Function }, config: Config) => {
  const url = backendUrl + "/configs"

  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(config)
  });

  if (response.status === 201) {
    await getConfigs(formSubmitContext.basicAuthToken, config.projectId, formSubmitContext.setConfigs)
  }
};

const getConfigs = async (basicAuthToken: string, currentProjectId: string, setConfigs: Function) => {
  const url = backendUrl + "/configs?projectId=" + currentProjectId;

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken
    })
  });

  if (response.status === 200) {
    const configsList = await response.json();

    setConfigs(
      configsList.map((item: { configId: string, name: string, apiUrl: string, token: string, sessionId: string, userId: string, channelId: string, channelType: string, formattingType: string }) => {
        return {
          id: item.configId,
          name: item.name,
          apiUrl: item.apiUrl,
          token: item.token,
          sessionId: item.sessionId,
          userId: item.userId,
          channelId: item.channelId,
          channelType: item.channelType,
          formattingType: item.formattingType
        }
      })
    )
  }
};

const updateConfigs = async (formSubmitContext: { basicAuthToken: string, editingConfigId: string, setConfigs: Function }, config: Config) => {
  const url = backendUrl + "/configs/" + formSubmitContext.editingConfigId;

  const response = await fetch(url, {
    method: "PUT",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(config)
  });

  if (response.status === 200) {
    await getConfigs(formSubmitContext.basicAuthToken, config.projectId, formSubmitContext.setConfigs)
  }
};

const deleteConfig = async (basicAuthToken: string, configId: string) => {
  const url = backendUrl + "/configs";

  await fetch(url + '/' + configId, {
    method: "DELETE",
    headers: new Headers({
      "Authorization": "Basic " + basicAuthToken,
      "Content-Type": "application/json"
    })
  });
};

function CustomPagination(props: any) {
  const { state, api } = props;
  const classes = useStyles();

  return (
    <div style={{display: "flex"}}>
      <Toolbar style={{justifyContent: "flex-end"}}>
        <Pagination className={classes.paginationStyle}
                    style={{justifyContent: "flex-start"}}
                    color="secondary"
                    count={state.pagination.pageCount}
                    onChange={(event, value) => api.current.setPage(value - 1)}/>
        <IconButton color="inherit" edge={'start'} onClick={() => props.handleClickModal('addConfig')}>
          <AddBoxIcon/>
        </IconButton>
        <Dialog open={props.openModal.addConfig}
                onClose={() => props.handleClickModal('addConfig')}
                aria-labelledby="form-dialog-title">
          <AppBar style={{position: 'relative'}}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => props.handleClickModal('addConfig')} aria-label="close">
                <CloseIcon/>
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <JsonForm schema={configSchema}
                      uiSchema={configUiSchema}
                      open={props.openModal.addConfig}
                      formSubmitContext={{
                        "basicAuthToken": props.authKey,
                        "setConfigs": props.setConfigs
                      }}
                      handleClose={() => props.handleClickModal('addConfig')}
                      onSubmit={addConfig}
                      record={{
                        projectId: props.currentProjectId
                      }}/>
          </DialogContent>
        </Dialog>
      </Toolbar>
    </div>
  )
};

const ConfigsSection = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [configs, setConfigs] = useState([]);
  const [editingConfigId, setEditingConfigId] = React.useState('none');
  const [deletingConfigId, setDeletingConfigId] = React.useState('none');
  const [openModal, setOpenModal] = useState({
    editConfig: false,
    deleteConfig: false,
    addConfig: false
  });
  const [editingConfig, setEditingConfig] = React.useState({});

  const handleClickModal = (modalName: string) => {
    setOpenModal(prevState => {
      // @ts-ignore
      return {...prevState, [modalName]: !openModal[modalName]}
    })
  };

  useEffect(() => {
    getConfigs(props.authKey, props.currentProjectId, setConfigs);
    // eslint-disable-next-line
  }, []);

  const configsColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: t("configs.config_table.name"),
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'apiUrl',
      headerName: t("configs.config_table.api_url"),
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'channelId',
      headerName: t("configs.config_table.channel_id"),
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'formattingType',
      headerName: t("configs.config_table.formatting_type"),
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'actions',
      headerName: t("configs.config_table.actions"),
      headerAlign: 'center',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params: ValueFormatterParams) => (
        <Toolbar>
          <IconButton color="inherit" aria-label="menu" onClick={() => {

            setEditingConfigId(params.row.id.toString());

            // @ts-ignore
            const obj = {...params.row};
            // @ts-ignore
            delete obj.id;
            // @ts-ignore
            setEditingConfig(obj)

            handleClickModal('editConfig');
          }}>
            <EditIcon/>
          </IconButton>
          <Dialog open={openModal.editConfig}
                  onClose={() => {
                    handleClickModal('editConfig')
                  }}
                  aria-labelledby="form-dialog-title"
                  maxWidth='md'>
            <AppBar style={{position: 'relative'}}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => handleClickModal('editConfig')} aria-label="close">
                  <CloseIcon/>
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <JsonForm schema={configSchema}
                        uiSchema={configUiSchema}
                        record={{
                          ...editingConfig,
                          projectId: props.currentProjectId
                        }}
                        formSubmitContext={{
                          "basicAuthToken": props.authKey,
                          "setConfigs": setConfigs,
                          "editingConfigId": editingConfigId
                        }}
                        open={openModal.editConfig}
                        onSubmit={updateConfigs}
                        handleClose={() => {
                          handleClickModal('editConfig')
                        }}/>
            </DialogContent>
          </Dialog>
          <IconButton color="inherit" aria-label="menu" onClick={() => {
            setDeletingConfigId(params.row.id.toString());
            handleClickModal('deleteConfig');
          }}>
            <DeleteForeverIcon/>
          </IconButton>
          <Dialog open={openModal.deleteConfig}
                  onClose={() => handleClickModal('deleteConfig')}
                  aria-labelledby="form-dialog-title">
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete selected items?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                handleClickModal('deleteConfig')
              }}
                      color="primary">
                Disagree
              </Button>
              <Button onClick={async () => {
                await deleteConfig(props.authKey, deletingConfigId);
                await getConfigs(props.authKey, props.currentProjectId, setConfigs);

                handleClickModal('deleteConfig');
              }}
                      color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      )
    }
  ];

  return (
    <div className={classes.tableStyle}>
      <DataGrid rows={configs}
                density='compact'
                className={classes.configsTableStyle}
                pageSize={20}
                hideFooterSelectedRowCount={true}
                columns={configsColumns.map((column:   GridColDef) => ({
                  ...column
                }))}
                filterModel={{
                  items: [
                    {columnField: 'name', operatorValue: 'contains', value: ''},
                  ],
                }}
                components={{
                  Pagination: (gridProps) => {
                    return <CustomPagination {...gridProps}
                                             openModal={openModal}
                                             handleClickModal={handleClickModal}
                                             authKey={props.authKey}
                                             setConfigs={setConfigs}
                                             currentProjectId={props.currentProjectId}/>
                  }
                }}/>
    </div>
  )
}

export default ConfigsSection;