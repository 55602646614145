import { useEffect, useState } from "react";

const useRecorder = () => {
  const [recordingURL, setRecordingURL] = useState("");
  const [recordingFile, setRecordingFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    if(!clear) {
      const handleData = e => {
        setRecordingURL(URL.createObjectURL(e.data));
        setRecordingFile(new File([e.data], ""));
      }

      recorder.addEventListener("dataavailable", handleData);
      return () => recorder.removeEventListener("dataavailable", handleData);
    }
    
    setClear(false)
    // eslint-disable-next-line
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const clearRecording = () => {
    setClear(true);
  };

  return [recordingFile, recordingURL, isRecording, startRecording, stopRecording, clearRecording];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;