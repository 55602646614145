import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import App from './App';

import './index.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#28527a'
    },
    secondary: {
      main: '#495464'
    },
  },
  shape: {
    borderRadius: 0
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
