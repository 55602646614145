import React, { useEffect, useState } from 'react';
import backendUrl from '../globalVars';
import {
  DataGrid,
  ValueFormatterParams,
  GridRowParams,
  GridSortModel,
  GridColDef
} from '@material-ui/data-grid';
import { Button, DialogContentText, Grid, IconButton, Typography, ButtonGroup } from '@material-ui/core';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import projectSchema from '../json_shema/projectSchema/projectSchema.json';
import JsonForm from '../FormCreator/JsonForm';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { DateTime } from 'luxon';
import EditIcon from "@material-ui/icons/Edit";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    tableHeader: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
      padding: '10px 10px',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    tablePaper: {
      height: 'calc(100vh - 118px)',
    },
    clickableTitle: {
      //cursor: 'pointer',
    },
    row: {
      cursor: 'pointer',
    }
  })
);

interface ProjectDto {
  projectId: string
  userId: string
  name: string
  description: string
  createdAt: string
  updatedAt: string,
  params: {
    name: string,
    type: string,
    comment: string,
    isOnStartOnly: boolean
  }[] | undefined
}

interface Project {
  id: string,
  number: number,
  name: string,
  date: string,
  params: {
    name: string,
    value?: any,
    comment?: string,
    isOnStartOnly?: boolean
  }[]
};

const getProjects = async (basicAuthToken: string, setProjectRows: Function) => {
  const url = backendUrl + '/projects';

  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Authorization': 'Basic ' + basicAuthToken
    })
  });

  if (response.status === 200) {
    const projectsList = await response.json();

    setProjectRows(
      projectsList.map((item: ProjectDto, index: number) => {
        return {
          id: item.projectId,
          number: index + 1,
          name: item.name,
          description: item.description,
          created: item.createdAt,
          updated: item.updatedAt,
          params: item.params !== undefined ?
            item.params.map(param => {
              return {
                name: param.name,
                type: param.type,
                comment: param.comment,
                isOnStartOnly: param.isOnStartOnly
              }
            }) : undefined
        }
      })
    )
  }
};

const addProject = async (formSubmitContext: { basicAuthToken: string, setProjectRows: Function }, newProject: Project) => {
  const url = backendUrl + '/projects';

  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Authorization': 'Basic ' + formSubmitContext.basicAuthToken,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(newProject)
  });

  if (response.status === 201) {
    getProjects(formSubmitContext.basicAuthToken, formSubmitContext.setProjectRows)
  }
};

const deleteProject = async (basicAuthToken: string, projectId: string) => {
  const url = `${backendUrl}/projects`;

  await fetch(url + '/' + projectId, {
    method: 'DELETE',
    headers: new Headers({
      'Authorization': 'Basic ' + basicAuthToken,
      'Content-Type': 'application/json'
    })
  })
};

interface UpdateProject {
  setId: string,
  name: string,
  index: number,
  description: string,
  type: string,
  params?: {
    name: string,
    value?: any,
    comment?: string,
    isOnStartOnly: boolean
  }[]
};

const updateProject = async (formSubmitContext: { basicAuthToken: string, editingProjectId: string, setProjectRows: Function }, updatingProject: UpdateProject) => {
  const url = backendUrl + "/projects/" + formSubmitContext.editingProjectId;

  const response = await fetch(url, {
    method: "PUT",
    headers: new Headers({
      "Authorization": "Basic " + formSubmitContext.basicAuthToken,
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(updatingProject)
  });

  if (response.status === 200) {
    getProjects(formSubmitContext.basicAuthToken, formSubmitContext.setProjectRows)
  }
};

const ProjectsList = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [projectRows, setProjectRows] = useState([]);
  const [editingProjectId, setEditingProjectId] = React.useState('none');
  const [deletingProjectId, setDeletingProjectId] = React.useState('none');
  const [isAddProjectDialogOpen, setAddProjectDialogOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState({
    addProject: false,
    editProject: false,
    deleteProject: false
  });
  const [editingProjectRecord, setEditingProjectRecord] = React.useState({});

  const dateFormatter = (params: ValueFormatterParams) => {
    return DateTime.fromISO(params.value as string).toFormat('dd.MM.yy HH:mm');
  };

  const handleClickModal = (modalName: string) => {
    setOpenModal(prevState => {
      // @ts-ignore
      return { ...prevState, [modalName]: !openModal[modalName] }
    })
  };

  const columns: GridColDef[] = [
    {
      field: 'number',
      headerName: '#',
      width: 70,
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: 'name',
      headerName: t("projects.project_table.name"),
      headerAlign: 'center',
      flex: 0.6,
    },
    {
      field: 'description',
      headerName: t("projects.project_table.description"),
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'created',
      headerName: t("projects.project_table.created"),
      headerAlign: 'center',
      width: 150,
      align: 'center',
      filterable: false,
      valueFormatter: dateFormatter,
    },
    {
      field: 'updated',
      headerName: t("projects.project_table.updated"),
      headerAlign: 'center',
      width: 150,
      align: 'center',
      filterable: false,
      valueFormatter: dateFormatter,
    },
    {
      field: 'actions',
      headerName: ' ',
      headerAlign: 'center',
      width: 100,
      align: 'center',
      filterable: false,
      disableClickEventBubbling: true,
      renderCell: (params: ValueFormatterParams) => (
        <ButtonGroup color='secondary' size='small'>
          <IconButton onClick={() => {

            setEditingProjectId(params.row.id.toString())

            // @ts-ignore
            const obj = { ...params.row };
            // @ts-ignore
            delete obj.id;
            // @ts-ignore
            delete obj.number;
            // @ts-ignore
            delete obj.created;
            // @ts-ignore
            delete obj.updated;
            // @ts-ignore
            setEditingProjectRecord(obj)

            handleClickModal('editProject');
          }}>
            <EditIcon />
          </IconButton>

          <IconButton onClick={() => {
            setDeletingProjectId(params.row.id.toString());
            handleClickModal('deleteProject');
          }}>
            <DeleteForeverIcon />
          </IconButton>
        </ButtonGroup>
      )
    },
  ];

  const sortModel: GridSortModel = [
    {
      field: 'created',
      sort: 'asc',
    },
  ];

  const toggleAddProjectDialog = () => {
    setAddProjectDialogOpen(!isAddProjectDialogOpen);
  }

  const onRowClicked = (params: GridRowParams) => {
    props.updateCurrentProjectId(params.row.id);
    props.updatePath('/sets');
    props.getBreadcrumbs({ currentProject: params.row.name });
    sessionStorage.setItem('currentProject', params.row.name)
  };

  useEffect(() => {
    getProjects(props.authKey, setProjectRows)
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Dialog
          open={isAddProjectDialogOpen}
          onClose={toggleAddProjectDialog}
        >
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge='start' color='inherit' onClick={toggleAddProjectDialog}
                aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <JsonForm schema={projectSchema}
              open={isAddProjectDialogOpen}
              formSubmitContext={{
                'basicAuthToken': props.authKey,
                'setProjectRows': setProjectRows
              }}
              handleClose={toggleAddProjectDialog}
              onSubmit={addProject}
              record={{ userId: props.userId }} />
          </DialogContent>
        </Dialog>

        <Dialog open={openModal.editProject}
          onClose={() => {
            handleClickModal('editProject')
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md">
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => handleClickModal('editProject')}
                aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <JsonForm schema={projectSchema}
              record={{
                ...editingProjectRecord,
                userId: props.userId
              }}
              formSubmitContext={{
                "basicAuthToken": props.authKey,
                "editingProjectId": editingProjectId,
                "setProjectRows": setProjectRows
              }}
              open={openModal.editProject}
              onSubmit={updateProject}
              handleClose={() => {
                handleClickModal('editProject')
              }} />
          </DialogContent>
        </Dialog>

        <Dialog open={openModal.deleteProject}
          onClose={() => handleClickModal('deleteProject')}
          aria-labelledby="form-dialog-title">
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => handleClickModal('deleteProject')}
                aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {t("common.delete")}
              </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              handleClickModal('deleteProject')
            }}
              color="primary">
              {t("common.disagree")}
              </Button>
            <Button onClick={async () => {
              await deleteProject(props.authKey, deletingProjectId);
              await getProjects(props.authKey, setProjectRows);

              handleClickModal('deleteProject');
            }}
              color="primary">
              {t("common.agree")}
              </Button>
          </DialogActions>
        </Dialog>

        <Grid item xs={12} sm={12}>
          <Grid container>
            <Grid item xs={10} sm={10}>
              <Typography variant='h6' className={classes.title}>
                {t("projects.projects")}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <div style={{ paddingLeft: '100px' }}>
                <IconButton onClick={toggleAddProjectDialog}>
                  <AddBoxOutlinedIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper square className={classes.tablePaper} >
            <DataGrid
              rows={projectRows}
              columns={columns}
              density='compact'
              pageSize={20}
              sortModel={sortModel}
              onRowClick={onRowClicked}
              disableSelectionOnClick={true}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ProjectsList;