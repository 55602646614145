import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Tooltip, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import AuthorizationForm from './Components/AuthorizationForm';
import ProjectsList from "./Components/ProjectsList";
import Suits from "./Components/Suits";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Badge from '@material-ui/core/Badge';
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import * as pkg from '../../package.json';
import { ObjectLiteral } from './types';
import {translation} from '../translation';
import './App.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
    },
    title: {
      flexGrow: 1,
      marginLeft: '20px',
      whiteSpace: 'nowrap'
    },
    langSelect: {
      color: "white"
    },
  }),
);

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {translation: translation.commonEn},
      ru: {translation: translation.commonRu}
    },
    lng: "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false }
  });

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function App() {
  const [lang, setLang] = useState("en")

  const classes = useStyles();

  const {t} = useTranslation();

  const changeLang = (e: React.ChangeEvent<{ value: unknown }>) => {
    setLang(e.target.value as string)
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang])

  const getAuthKey = (basicAuthToken: string, userId: string) => {
    sessionStorage.setItem('auth_token', basicAuthToken);
    sessionStorage.setItem('user_id', userId);
  };

  const isLoggedIn = () => {
    if (sessionStorage.getItem('is_logged_in') === 'true' && sessionStorage.getItem('is_logged_in') !== null) {
      return true
    } else {
      return false
    }
  };

  const [loggedIn, setLoggedIn] = useState(isLoggedIn());

  const logIn = () => {
    sessionStorage.setItem('is_logged_in', 'true');
    setLoggedIn(true)
  };

  const logOut = () => {
    sessionStorage.setItem('is_logged_in', 'false')
    setLoggedIn(false)
  };

  const getRoute = () => {
    const path: string | null = sessionStorage.getItem('path');

    if (path !== null) {
      return path
    } else {
      return '/projects'
    }
  };

  const [path, setPath] = useState(getRoute());
  const [pageHierarchy] = useState<Array<string>>(['/projects', '/sets']);

  const updatePath = (path: string) => {
    sessionStorage.setItem('path', path);
    setPath(path)
  };

  const getCurrentProjectId = () => {
    const currentProjectId: string | null = sessionStorage.getItem('currentProjectId');

    if (path !== null) {
      return currentProjectId
    } else {
      return 'none'
    }
  };

  const getCurrentBreadcrumbs = () => {
    const currentProject: string | null = sessionStorage.getItem('currentProject');

    return {
      currentProject: currentProject !== null ? currentProject : '',
      currentSet: '',
      currentCase: '',
      currentRun: '',
      currentRunCase: ''
    }
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [currentProjectId, setCurrentProjectId] = useState(getCurrentProjectId());
  const [breadcrumbs, setBreadcrumbs] = useState(getCurrentBreadcrumbs());

  const getBreadcrumbs = (obj: ObjectLiteral) => {
    setBreadcrumbs({ ...breadcrumbs, ...obj })
  };

  const resetBreadcrumbs = () => {
    setBreadcrumbs({
      currentProject: '',
      currentSet: '',
      currentCase: '',
      currentRun: '',
      currentRunCase: ''
    });

    sessionStorage.setItem('currentProject', '');
  };

  const updateCurrentProject = (id: string) => {
    sessionStorage.setItem('currentProjectId', id);
    setCurrentProjectId(id);
    getBreadcrumbs({ currentProject: '' })
  };

  const openSnackbarError = (text: string) => {
    setSnackbarOpen(true);
    setSnackbarText(text)
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  if (loggedIn !== true) {
    return (
      <div className={classes.root}>
        <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleSnackbarClose} severity="error" variant="outlined">
            {snackbarText}
          </Alert>
        </Snackbar>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Router>
              <Route path="/">
                {<Redirect to="/login" />}
              </Route>
              <Route path={'/login'}
                     component={() => <AuthorizationForm getAuthKey={getAuthKey}
                                                         openSnackbarError={openSnackbarError}
                                                         logIn={logIn}
                                                         updatePath={updatePath} />}>
              </Route>
            </Router>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        <Snackbar open={snackbarOpen} autoHideDuration={5000} onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={handleSnackbarClose} severity="error" variant="outlined">
            {snackbarText}
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <AppBar position="static">
              <Toolbar>
                <IconButton style={{ width: '10%' }} className={classes.menuButton} color="inherit" aria-label="menu"
                            onClick={() => {
                              if (pageHierarchy.indexOf(window.location.pathname) > 0) {
                                updatePath(pageHierarchy[pageHierarchy.indexOf(window.location.pathname) - 1]);
                                updateCurrentProject('none');
                                resetBreadcrumbs()
                              }
                            }}>
                  <Tooltip title='Go to projects' enterDelay={1000}>
                    <ArrowBackIcon />
                  </Tooltip>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  <Badge badgeContent={pkg.version} color='primary'>
                    {t("common.test_suite")}
                  </Badge>
                </Typography>
                <Breadcrumbs style={{ width: '70%', display: "flex", justifyContent: 'center' }} aria-label="breadcrumb">
                  {breadcrumbs.currentProject !== '' ?
                    <Typography style={{ color: 'white' }}>
                      {breadcrumbs.currentProject}
                    </Typography> : null}
                  {breadcrumbs.currentSet !== '' ?
                    <Typography style={{ color: 'white' }}>
                      {breadcrumbs.currentSet}
                    </Typography> : null}
                  {breadcrumbs.currentCase !== '' ?
                    <Typography style={{ color: 'white' }}>
                      {breadcrumbs.currentCase}
                    </Typography> : null}
                  {breadcrumbs.currentRun !== '' ?
                    <Typography style={{ color: 'white' }}>
                      {breadcrumbs.currentRun}
                    </Typography> : null}
                  {breadcrumbs.currentRunCase !== '' ?
                    <Typography style={{ color: 'white' }}>
                      {breadcrumbs.currentRunCase}
                    </Typography> : null}
                </Breadcrumbs>
                <Typography>{t("common.change_lang")} </Typography>
                <Select
                  className={classes.langSelect}
                  value={lang}
                  onChange={changeLang}
                >
                  <MenuItem value="en">
                  </MenuItem>
                  <MenuItem value="en">en</MenuItem>
                  <MenuItem value="ru">ru</MenuItem>
                </Select>
                <IconButton style={{ width: '10%' }} className={classes.menuButton} color="inherit" aria-label="menu"
                            onClick={logOut}>
                  <Tooltip title='Logout' enterDelay={1000}>
                    <ExitToAppOutlinedIcon />
                  </Tooltip>
                </IconButton>
              </Toolbar>
            </AppBar>
          </Grid>
          <Router>
            <Redirect to={path} />
            <Route path={'/projects'}>
              {<ProjectsList authKey={sessionStorage.getItem('auth_token')}
                             getBreadcrumbs={getBreadcrumbs}
                             updateCurrentProjectId={updateCurrentProject}
                             updatePath={updatePath}
                             userId={sessionStorage.getItem('user_id')} />}
            </Route>
            <Route path={'/sets'}>
              {<Suits authKey={sessionStorage.getItem('auth_token')}
                      getBreadcrumbs={getBreadcrumbs}
                      breadcrumbs={breadcrumbs}
                      currentProjectId={currentProjectId}
                      openSnackbarError={openSnackbarError}
                      updatePath={updatePath} />}
            </Route>
          </Router>
        </Grid>
      </div>
    )
  }
}

export default App;
