import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SetsSection from './SetsSections/SetsSection';
import RunsSection from "./SetsSections/RunsSection";
import ConfigsSection from "./SetsSections/ConfigsSection";
import ParamsSection from "./SetsSections/ParamsSection";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#ececec',
      color: '#222',
    }
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
};

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
};

const Suits = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleBreadcrumbs = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    props.getBreadcrumbs({...props.breadcrumbs, currentSet: '', currentCase: '', currentRun: '', currentRunCase: ''});
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <AppBar position="static" style={{display: "flex" }} className={classes.appBar}>
          <Tabs style={{display: "flex"}} value={value} onChange={handleBreadcrumbs}>
            <Tab label={t("suits.sets")} {...a11yProps(0)}/>
            <Tab label={t("suits.runs")} {...a11yProps(1)}/>
            <Tab label={t("suits.configs")} {...a11yProps(2)}/>
            <Tab label={t("suits.params")} {...a11yProps(3)}/>
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TabPanel value={value} index={0}>
          <SetsSection {...props}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RunsSection {...props}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConfigsSection {...props}/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ParamsSection {...props}/>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default Suits;