import React, {useEffect, useState, useRef} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: "95%"
      },
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    formItem: {
      margin: theme.spacing(1),
      width: '100%'
    },
    buttonBlock: {
      margin: theme.spacing(1),
      marginTop: 30,
      width: '100%'
    }
  }),
);

interface JsonItem {
  type: string,
  title: string,
  content: string,
  items: string[],
  objects: {
    keys: string | string[],
    values: string | string[]
  }
};

// @ts-ignore
const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const KeyValueEditor = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const [usedParams, setUsedParams] = useState<string[]>(props.projectFormData[props.item.content] ? props.projectFormData[props.item.content].map((param: { name: string }) => param.name) : []);

  const getOptions = () => {
    const options = [];

    if (props.item.objects.keys !== "userItems" && typeof props.item.objects.keys !== "string") {
      options.push(props.item.objects.keys.filter().map((option: string) =>
          <MenuItem value={option} key={option + Math.random().toString()}>{option.toString()}</MenuItem>
        )
      )
    } else {
      for (let key in props.objectKeysSource) {
        if (key === props.item.content) {
          options.push(props.objectKeysSource[key].map((option: { id: string, name: string, isOnStartOnly: boolean }) =>
            <MenuItem disabled={usedParams.includes(option.name) !== true ? false : true} value={option.name}
                      key={option.name + Math.random().toString()}>{`${option.name} ${option.isOnStartOnly ? '(start only)' : ''}`}</MenuItem>)
          )
        }
      }
    }

    return options
  };

  if (props.projectFormData[props.item.content]) {
    return (
      <React.Fragment>
        {props.projectFormData[props.item.content].map((paramItem: { name: string, value: string | undefined }) => {
            return (
              <FormControl key={paramItem.name + '_FC'}
                //@ts-ignore
                           className={classes.formItem}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <InputLabel style={{width: "90%"}}>{t(`${props.item.title}`) + ' key'}</InputLabel>
                    <Select style={{width: "90%"}}
                            defaultValue={paramItem.name}
                            onChange={e => {
                              const obj = props.projectFormData;

                              if (e.target.value !== '') {
                                obj[props.item.content] = obj[props.item.content].map((objItem: { name: string }) =>
                                  objItem.name === paramItem.name ? {
                                    name: e.target.value,
                                    value: paramItem.value,
                                    isOnStartOnly: props.objectKeysSource[props.item.content].find((sourceItem: { name: string }) => sourceItem.name === e.target.value).isOnStartOnly
                                  } : objItem
                                )

                                setUsedParams([...usedParams].map(usedParam => usedParam === paramItem.name ? e.target.value as string : usedParam))
                              } else {
                                obj[props.item.content] = obj[props.item.content].filter((objItem: { name: string }) =>
                                  objItem.name !== paramItem.name
                                )

                                setUsedParams([...usedParams].filter(usedParam => usedParam !== paramItem.name))
                              }

                              props.setProjectFormData(obj);

                              props.handleError(props.item.content)
                            }}>
                      <MenuItem key={props.item.content + "_null" + Math.random().toString()} value="">
                        <em>None</em>
                      </MenuItem>
                      {getOptions()}
                    </Select>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField label={t(`${props.item.title}`) + " value"}
                               variant="outlined"
                               style={{width: "90%"}}
                               //@ts-ignore
                               required={props.required[props.item.content]}
                               onChange={e => {
                                 const obj = props.projectFormData;

                                 obj[props.item.content] = obj[props.item.content].map((objItem: { name: string, value: string | undefined }) =>
                                   objItem.name === paramItem.name ? {
                                     name: paramItem.name,
                                     value: e.target.value,
                                     isOnStartOnly: props.objectKeysSource[props.item.content].find((sourceItem: { name: string }) => sourceItem.name === paramItem.name).isOnStartOnly
                                   } : objItem
                                 );

                                 props.setProjectFormData(obj);

                                 props.handleError(props.item.content)
                               }}
                               error={props.errors[props.item.content]}
                               rows={5}
                               defaultValue={paramItem.value}/>
                  </Grid>
                </Grid>
              </FormControl>
            )
          }
        )}
        {usedParams.length < props.objectKeysSource[props.item.content].length ?
          <FormControl key={"new_" + props.item.content + '_FC'}
            //@ts-ignore
                       required={props.required[props.item.content]}
                       className={classes.formItem}
                       style={{display: "flex"}}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <InputLabel style={{width: "90%"}}>{t(`${props.item.title}`) + ' key'}</InputLabel>
                <Select style={{width: "90%"}}
                        key={props.item.content + '_S'}
                        defaultValue={''}
                        value={''}
                        onChange={e => {
                          const obj = props.projectFormData;

                          obj[props.item.content].push(
                            {
                              name: e.target.value,
                              isOnStartOnly: props.objectKeysSource[props.item.content].find((sourceItem: { name: string }) => sourceItem.name === e.target.value).isOnStartOnly
                            }
                          );

                          if (usedParams.includes(e.target.value as string) !== true) {
                            setUsedParams([...usedParams, e.target.value as string])
                          }
                          ;

                          props.setProjectFormData(obj);

                          props.handleError(props.item.content)
                        }}>
                  <MenuItem key={props.item.content + "_null" + Math.random().toString()} value="">
                    <em>None</em>
                  </MenuItem>
                  {getOptions()}
                </Select>
              </Grid>
            </Grid>
          </FormControl> : null
        }
      </React.Fragment>
    )
  } else {
    return (
      <FormControl key={props.item.title}
                   //@ts-ignore
                   required={props.required[props.item.content]}
                   className={classes.formItem}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <InputLabel style={{width: "90%"}}>{t(`${props.item.title}`) + ' key'}</InputLabel>
            <Select style={{width: "90%"}}
                    defaultValue={props.projectFormData[props.item.content] === undefined ? '' : props.projectFormData[props.item.content]}
                    onChange={e => {
                      const obj = props.projectFormData;

                      obj[props.item.content] = [{
                        name: e.target.value,
                        isOnStartOnly: props.objectKeysSource[props.item.content].find((sourceItem: { name: string }) => sourceItem.name === e.target.value).isOnStartOnly
                      }]

                      if (usedParams.includes(e.target.value as string) !== true) {
                        setUsedParams([e.target.value as string])
                      }
                      ;

                      props.setProjectFormData(obj);

                      props.handleError(props.item.content)
                    }}>
              <MenuItem key={props.item.content + "_null" + Math.random().toString()} value="">
                <em>None</em>
              </MenuItem>
              {getOptions()}
            </Select>
          </Grid>
        </Grid>
      </FormControl>
    )
  }
}

const JsonForm = (props: any) => {
  const classes = useStyles();

  const {t} = useTranslation();

  const items: [] = props.schema.properties;
  const required: Object = props.schema.required ? props.schema.required.reduce((obj: Object, item: string) => {
    // @ts-ignore
    obj[item] = true
    return obj
  }, {}) : {};

  const [blockActions, setBlockActions] = useState(false);

  const formData = () => {
    let obj: { [key: string]: any } = {};

    if (props.record) {
      obj = {...props.record}
    } else {
      for (let item in items) {
        obj[item] = ''
      }
    };

    return obj;
  };

  const [projectFormData, setProjectFormData] = useState(formData());

  const prevRecord = usePrevious(props.record);

  useEffect(() => {
    const obj = projectFormData;

    if (prevRecord) {
      Object.keys(props.record).forEach(k => {
        // @ts-ignore
        if ((!prevRecord[k] || prevRecord[k] !== props.record[k]) && projectFormData[k] !== props.record[k]) obj[k] = props.record[k]
      });
    }
    
    setProjectFormData(obj);
    // eslint-disable-next-line
  }, [props.record]);
  
  const errReducer = (errArr: any, arrItem: any) => {
    if (props.schema.required) {
      props.schema.required.forEach((requiredItem: string) => {
        const errArrKey = arrItem[0];

        if (errArrKey === requiredItem) {
          // @ts-ignore
          if (!projectFormData[errArrKey] || projectFormData[errArrKey] === '') {
            return errArr[errArrKey] = true
          } else {
            return errArr[errArrKey] = false
          }
        }
      })
    }
    ;

    return errArr
  };

  const [errors, setErrors] = useState<any>(Object.entries(items).reduce(errReducer, {}));

  const handleError = (item: string) => {
    if (props.schema.required) {
      props.schema.required.forEach((requiredItem: string) => {
        if (requiredItem === item) {
          // @ts-ignore
          if (projectFormData[item] === '') {
            // @ts-ignore
            setErrors(prevState => {
              return {...prevState, [item]: true}
            })
          } else {
            // @ts-ignore
            setErrors(prevState => {
              return {...prevState, [item]: false}
            })
          }
        }
      })
    }
  };

  const getFields = Object.values(items).map((item: JsonItem) => {
    let field = {};

    const disabled = () => {
      if (props.uiSchema) {
        if (props.uiSchema[item.content]) {
          if (props.uiSchema[item.content].disabled) {
            return JSON.parse(props.uiSchema[item.content].disabled)
          } else {
            return false
          }
        }
      }
    };

    const multiline = () => {
      if (props.uiSchema) {
        if (props.uiSchema[item.content]) {
          if (props.uiSchema[item.content].multiline) {
            return JSON.parse(props.uiSchema[item.content].multiline)
          } else {
            return false
          }
        }
      }
    };

    const readonly = () => {
      if (props.uiSchema) {
        if (props.uiSchema[item.content]) {
          if (props.uiSchema[item.content].readonly) {
            return JSON.parse(props.uiSchema[item.content].readonly)
          } else {
            return false
          }
        }
      }
    };

    const rowCount = () => {
      if (props.uiSchemad) {
        if (props.uiSchema[item.content]) {
          if (props.uiSchema[item.content].rows) {
            return JSON.parse(props.uiSchema[item.content].rows)
          } else {
            return 5
          }
        }
      }
    };

    const multipleSelect = () => {
      if (props.uiSchema && props.uiSchema[item.content]) {
        if (props.uiSchema[item.content].multipleSelect) {
          return JSON.parse(props.uiSchema[item.content].multipleSelect)
        } else {
          return false
        }
      }
    };

    const isRadio = () => {
      if (props.uiSchema && props.uiSchema[item.content]) {
        if (props.uiSchema[item.content].radio) {
          return JSON.parse(props.uiSchema[item.content].radio)
        } else {
          return false
        }
      }
    };

    if (item.type === 'string') {
      field =
        <FormControl key={item.title}
                     className={classes.formItem}
                     style={{width: "100%", display: "flex", flexDirection: "row", alignItems: 'center'}}>
          <TextField label={t(`${item.title}`)}
                     style={{width: props.fieldFeatures && props.fieldFeaturesFields[item.content]? "80%": "100%"}}
                     variant="outlined"
                     // @ts-ignore
                     required={required[item.content]}
                     onChange={e => {
                       const obj = projectFormData;

                       // @ts-ignore
                       obj[item.content] = e.target.value;

                       setProjectFormData(obj);

                       handleError(item.content)
                     }}
                     error={errors[item.content]}
                     multiline={multiline()}
                     disabled={disabled()}
                     inputProps={{readOnly: readonly()}}
                     rows={rowCount()}
                     // @ts-ignore
                     defaultValue={projectFormData[item.content]}/>
          {(props.fieldFeatures && props.fieldFeaturesFields[item.content]) || null}
        </FormControl>
    } else if (item.type === 'number') {
      field =
        <FormControl key={item.title}
                     className={classes.formItem}>
          <TextField label={t(`${item.title}`)}
                     variant="outlined"
                     // @ts-ignore
                     required={required[item.content]}
                     type="number"
                     onChange={e => {
                       const obj = projectFormData;

                       // @ts-ignore
                       obj[item.content] = parseInt(e.target.value);

                       setProjectFormData(obj)
                     }}
                     disabled={disabled()}
                     // @ts-ignore
                     defaultValue={projectFormData[item.content]}/>
        </FormControl>
    } else if (item.type === 'enum') {
      const arrayComparison = (array1: string[], array2: string[]) => {
        return (array1.length === array2.length) && array1.every(function (element, index) {
          return element === array2[index];
        })
      }

      const getOptions = () => {
        const options = [];

        if (arrayComparison(item.items, ["userItems"]) === false) {
          options.push(item.items.map((option: string) => <MenuItem key={item.content + Math.random().toString()}
                                                                    value={option}>{option.toString()}</MenuItem>))
        } else {
          for (let key in props.enumSource) {
            if (key === item.content) {
              options.push(props.enumSource[key].map((option: { id: string, name: string }) => <MenuItem
                key={item.content + Math.random().toString()} value={option.id}>{option.name}</MenuItem>))
            }
          }
        }

        return options
      };

      if (multipleSelect() !== true) {
        if (!isRadio()) {
        field =
          <FormControl key={item.title}
                       style={{margin: "8px 16px", width: "95%"}}
                       error={errors[item.content]}
            // @ts-ignore
                       required={required[item.content]}
                       className={classes.formItem}>
            <InputLabel style={{margin: "0 14px"}}>{t(`${item.title}`)}</InputLabel>
            <Select // @ts-ignore 
                    defaultValue={!projectFormData[item.content] ? "" : projectFormData[item.content]}
                    style={{padding: "0 14px"}}
                    onChange={e => {
                      const obj = projectFormData;

                      // @ts-ignore
                      obj[item.content] = e.target.value;

                      setProjectFormData(obj);

                      handleError(item.content)
                    }}>
              <MenuItem key={item.content + "_null" + Math.random().toString()} value="">
                <em>None</em>
              </MenuItem>
              {getOptions()}
            </Select>
          </FormControl>
        } else {
          field =
            <FormControl style={{margin: "0 16px"}}>
              <FormLabel>{t(`${item.title}`)}</FormLabel>
                <RadioGroup
                  style={{flexDirection: "row"}}
                  key={item.title}
                  // @ts-ignore
                  defaultValue={!projectFormData[item.content] ? item.items[0] : projectFormData[item.content]}
                  onChange={e => {
                    const obj = projectFormData;

                    // @ts-ignore
                    obj[item.content] = e.target.value;

                    setProjectFormData(obj);

                    handleError(item.content)
                  }}
                >
                  {item.items.map(i => <FormControlLabel key={item.content + Math.random().toString()} value={i} control={<Radio />} label={i.toString()} />)}
              </RadioGroup>
            </FormControl>
      }
      } else {
        const autocompleteOptions: [] = props.enumSource[item.content].map((opt: Object) => {
          return Object.values(opt)[0]
        })

        field =
          <FormControl key={item.title}
                       className={classes.formItem}>
            <Autocomplete multiple
                          options={autocompleteOptions}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option}
                          renderOption={(option, {selected}) => (
                            <React.Fragment>
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                checked={selected}
                              />
                              {option}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label={t(`${item.title}`)}/>
                          )}
                          onChange={(e, values) => {
                            const obj = projectFormData;

                            // @ts-ignore
                            obj[item.content] = values.map((value) => {
                              return {name: value}
                            });

                            setProjectFormData(obj);

                            handleError(item.content)
                          }}/>
          </FormControl>
      }
    } else if (item.type === 'keyValue') {
      field = <KeyValueEditor item={item}
                              projectFormData={projectFormData}
                              setProjectFormData={setProjectFormData}
                              handleError={handleError}
                              required={required}
                              errors={errors}
                              objectKeysSource={props.objectKeysSource}/>
    }

    return field
  });

  const getButtons = () => {
    if (props.buttons === undefined) {
      const importButton = () => {

        if (props.import === true) {
          return props.importButton
        }
      }

      return (
        <React.Fragment>
          {importButton()}
          <Button disabled={blockActions} onClick={() => props.handleClose()} color="primary">
            {t("common.cancel")}
          </Button>
          <Button disabled={blockActions} onClick={() => {
            setBlockActions(true);

            const notSubmittable: boolean = Object.entries(errors).some((error) => error[1] === true);

            if (notSubmittable !== true) {
              if (props.formSubmitContext === undefined) {
                props.onSubmit(projectFormData)
              } else {
                props.onSubmit(props.formSubmitContext, projectFormData)
              }

              props.handleClose()
            }
          }}
                  color="primary">
            {t("common.submit")}
          </Button>
        </React.Fragment>
      )
    } else if (props.buttons === null) {
      return (
        <React.Fragment>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {props.buttons(projectFormData, errors)}
        </React.Fragment>
      )
    }
  }

  return (
    <React.Fragment>
      <form className={classes.root} noValidate style={props.style}>
        {getFields}
        <div className={classes.buttonBlock}>
          {getButtons()}
        </div>
      </form>
    </React.Fragment>
  )
}

export default JsonForm;